import { useState } from "react";
import { useSetRecoilState } from "recoil";
import useErrorHandler from "../hooks/useErrorHandler";
import { login } from "../apis/authorizationTokenApis";
import { authorizationTokenState } from "../recoil/atoms";
import { useNavigate } from "react-router-dom";
import FormWrapper from "../components/common/FormWrapper";
import { useFormik } from "formik";
import { t } from "i18next";
import P002InputText from "../components/input/P002InputText";
import P002Password from "../components/input/P002Password";
import { Button } from "primereact/button";
import { validateInputText } from "../common/FormValidationUtils";

const LoginPage = () => {
  const [loadingLogIn, setLoadingLogIn] = useState(false);

  const setAuthorizationToken = useSetRecoilState(authorizationTokenState);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validate: (values) => {
      let errors = {};

      validateInputText("username", values.username, true, 4, 20, errors);
      validateInputText("password", values.password, true, 6, 20, errors);

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        username: values.username,
        password: values.password,
      };

      setLoadingLogIn(true);
      try {
        const response = await login(body);
        setAuthorizationToken(response.data);
      } catch (error) {
        handleError(error);
        setLoadingLogIn(false);
      }
    },
  });

  return (
    <div className="flex justify-content-center align-items-center">
      <div className="mt-8 max-w-30rem">
        <FormWrapper title={t("Login")}>
          <form className="grid" onSubmit={formik.handleSubmit}>
            <P002InputText
              formik={formik}
              name="username"
              label={t("Username")}
              rootCol={12}
              inputCol={12}
              required
              iconClassName="pi pi-user"
            />
            <P002Password
              formik={formik}
              name="password"
              label={t("Password")}
              rootCol={12}
              inputCol={12}
              required
              iconClassName="pi pi-key"
            />
            <div className="col-12">
              <Button
                type="submit"
                label={t("Log In")}
                loading={loadingLogIn}
              />
            </div>
            <div className="col-12">
              <Button
                className="p-1"
                label={t("Forgot password?")}
                link
                onClick={() => {
                  navigate("/reset-password");
                }}
              />
            </div>
          </form>
        </FormWrapper>
      </div>
    </div>
  );
};

export default LoginPage;
