import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { getProduct } from "../../apis/productApis";
import { isNumber } from "../../common/CommonUtils";
import ProductForm from "../../components/forms/ProductForm";
import { t } from "i18next";
import { Button } from "primereact/button";
import { useToast } from "../../components/common/ToastProvider";

const ProductUpdatePage = () => {
  const { productId } = useParams();

  const [product, setProduct] = useState({});

  const [loadingGetProduct, setLoadingGetProduct] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const { showError } = useToast();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getProduct(productId);
        setProduct(response.data);
        setLoadingGetProduct(false);
      } catch (error) {
        handleError(error);
        navigate("/products/list");
      }
    };

    if (isNumber(productId)) {
      fetchProduct();
    } else {
      showError("Not valid id");
      navigate("/products/list");
    }
  }, []);

  return (
    !loadingGetProduct && (
      <>
        <Button
          className="p-1"
          label={`< ${t("List Value", { value: t("Product") })}`}
          link
          onClick={() => {
            navigate("/products/list");
          }}
        />
        <ProductForm
          action="UPDATE"
          product={product}
          setProduct={setProduct}
        />
      </>
    )
  );
};

export default ProductUpdatePage;
