import { TeamOutlined } from "@ant-design/icons";
import { formatTL } from "../../common/CommonUtils";
import dayjs from "dayjs";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { createOrder } from "../../apis/orderApis";
import OrderContent from "./OrderContent";
import { t } from "i18next";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import DashedDivider from "../common/DashedDivider";
import SolidDivider from "../common/SolidDivider";

const OrderCard = (props) => {
  const {
    type,
    table,
    emptyTables,
    order,
    categories,
    products,
    orderProducts,
    payments,
    setOrders,
    setOrderProducts,
    setPayments,
    billPrintSettings,
  } = props;

  const tableName =
    type === "TABLE"
      ? table.name
      : type === "TAKEAWAY"
      ? t("Takeaway")
      : t("Delivery");

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);

  const handleError = useErrorHandler();

  const hasOrder = order.id !== undefined;

  const onClickCreateOrder = async () => {
    const body = {
      type,
      tableId: type === "TABLE" ? table.id : null,
    };
    setLoadingCreateOrder(true);
    try {
      const response = await createOrder(body);
      setOrders((prevState) => [...prevState, response.data]);
    } catch (error) {
      handleError(error);
    }
    setLoadingCreateOrder(false);
  };

  return (
    <>
      <Card
        title={
          <>
            <div className="p-2">{tableName}</div>
            <SolidDivider />
          </>
        }
        className={`cursor-pointer bg-${hasOrder ? "red-500" : "green-500"}`}
        pt={{
          title: { className: "text-base font-semibold" },
          body: { className: "p-0" },
          content: { className: "p-2" },
        }}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <div className="inline-block">
          {type === "TABLE" && (
            <>
              <TeamOutlined />
              {hasOrder
                ? ` ${order.customerCount}/${table.capacity}`
                : ` ${table.capacity}`}
            </>
          )}
        </div>

        <div className="flex">
          <div className="flex-grow-1">
            {hasOrder
              ? formatTL(
                  orderProducts.reduce(
                    (sum, orderProduct) =>
                      sum +
                      orderProduct.quantity * orderProduct.productPrice * 100,
                    0
                  ) / 100
                )
              : t("Available")}
          </div>
          {hasOrder && (
            <div>{dayjs(order.createdDateTime).format("HH:mm")}</div>
          )}
        </div>
      </Card>
      <Dialog
        pt={{
          header: {
            className: `py-2 text-black-alpha-90 bg-${
              hasOrder ? "red-500" : "green-500"
            }`,
          },
          content: { className: "p-2" },
          closeButtonIcon: { className: "text-black-alpha-90" },
        }}
        header={tableName}
        focusOnShow={false}
        draggable={false}
        maximized
        visible={drawerOpen}
        onHide={() => {
          setDrawerOpen(false);
        }}
      >
        {hasOrder ? (
          <OrderContent
            emptyTables={emptyTables}
            categories={categories}
            products={products}
            order={order}
            orderProducts={orderProducts}
            payments={payments}
            setOrders={setOrders}
            setOrderProducts={setOrderProducts}
            setPayments={setPayments}
            billPrintSettings={billPrintSettings}
          />
        ) : (
          <div className="grid">
            <div className="col-12">
              <Button
                className="w-10rem"
                label={t("Create Value", { value: t("Order") })}
                onClick={() => {
                  onClickCreateOrder();
                }}
                loading={loadingCreateOrder}
              />
            </div>
            {type === "TABLE" && (
              <div className="col-12">
                <Panel header={t("Value Info", { value: t("Table") })}>
                  <div>
                    <TeamOutlined />
                    {t("Capacity")}:{` ${table.capacity}`}
                  </div>
                </Panel>
              </div>
            )}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default OrderCard;
