import { Password } from "primereact/password";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

const P002Password = (props) => {
  const {
    label,
    name,
    formik,
    rootCol,
    rootLgCol,
    inputCol,
    inputLgCol,
    required,
    iconClassName,
  } = props;

  return (
    <div
      className={`${rootCol ? `col-${rootCol} p-0` : ""} ${
        rootLgCol ? `lg:col-${rootLgCol}` : ""
      } `}
    >
      <div
        className={`${inputCol ? "col-" + inputCol : ""} 
        ${inputLgCol ? `lg:col-${inputLgCol}` : ""} 
        flex flex-column`}
      >
        <label className="pb-1">
          {required && <span className="text-red-600">* </span>}
          {label}
        </label>
        {iconClassName ? (
          <IconField iconPosition="left">
            <InputIcon className={`z-1 ${iconClassName}`}></InputIcon>
            <Password
              pt={{
                input: { className: "w-full", style: { paddingLeft: "40px" } },
                iconField: { root: { className: "w-full" } },
                root: { className: "w-full" },
              }}
              autoComplete="new-password"
              name={name}
              value={formik.values[name]}
              onChange={formik.handleChange}
              invalid={formik.errors[name]}
              toggleMask
              feedback={false}
            />
          </IconField>
        ) : (
          <Password
            pt={{
              input: { className: "w-full" },
              iconField: { root: { className: "w-full" } },
            }}
            autoComplete="new-password"
            name={name}
            value={formik.values[name]}
            onChange={formik.handleChange}
            invalid={formik.errors[name]}
            toggleMask
            feedback={false}
          />
        )}
        {formik.errors[name] && (
          <small className="p-error">{formik.errors[name]}</small>
        )}
      </div>
    </div>
  );
};

export default P002Password;
