import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import CategoryForm from "../../components/forms/CategoryForm";
import { t } from "i18next";

const CategoryCreatePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        className="p-1"
        label={`< ${t("List Value", { value: t("Category") })}`}
        link
        onClick={() => {
          navigate("/categories/list");
        }}
      />
      <CategoryForm action="CREATE" />
    </>
  );
};

export default CategoryCreatePage;
