import { deleteCategory, searchListCategories } from "../../apis/categoryApis";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useToast } from "../../components/common/ToastProvider";
import { t } from "i18next";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { Button } from "primereact/button";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";

const CategoryListPage = () => {
  const [categories, setCategories] = useState([]);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingDeleteCategory, setLoadingDeleteCategory] = useState({});

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const navigate = useNavigate();

  const groupedCategories = Object.groupBy(
    categories,
    ({ parentCategoryId }) => parentCategoryId
  );

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingSearchListCategories(true);
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  const onClickDeleteYes = async (category) => {
    setLoadingDeleteCategory((prevState) => ({
      ...prevState,
      [category.id]: true,
    }));
    try {
      await deleteCategory(category.id);
      showInfo(t("Value deleted.", { value: category.name }));
      setCategories((prevState) =>
        prevState.filter((item) => item.id !== category.id)
      );
    } catch (error) {
      handleError(error);
    }
    setLoadingDeleteCategory((prevState) => ({
      ...prevState,
      [category.id]: false,
    }));
  };

  return (
    <div>
      <TreeTable
        showGridlines
        value={
          Object.keys(groupedCategories).length > 0
            ? groupedCategories[null].map((category) => ({
                key: category.id,
                data: category,
                children: groupedCategories[category.id]
                  ? groupedCategories[category.id].map((category) => ({
                      key: category.id,
                      data: category,
                    }))
                  : [],
              }))
            : []
        }
        loading={loadingSearchListCategories}
      >
        <Column field="name" header={t("Name")} expander></Column>
        <Column field="position" header={t("Position")}></Column>
        <Column
          header={t("Actions")}
          body={({ data: category }) => (
            <div className="flex flex-row">
              <Button
                size="small"
                className="p-1 mr-1"
                link
                label={t("Update")}
                disabled={loadingDeleteCategory[category.id]}
                onClick={() => {
                  navigate(`/categories/${category.id}/update`);
                }}
              />
              <Button
                size="small"
                className="p-1"
                link
                label={t("Delete")}
                loading={loadingDeleteCategory[category.id]}
                onClick={(event) => {
                  confirmPopup({
                    target: event.currentTarget,
                    message: t("Are you sure to delete the value?", {
                      value: t("Category"),
                    }),
                    icon: "pi pi-info-circle",
                    defaultFocus: "reject",
                    acceptClassName: "p-button-danger",
                    acceptLabel: t("Yes"),
                    rejectLabel: t("No"),
                    accept: () => {
                      onClickDeleteYes(category);
                    },
                  });
                }}
              />
            </div>
          )}
        ></Column>
      </TreeTable>
      <ConfirmPopup />
    </div>
  );
};

export default CategoryListPage;
