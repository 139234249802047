import { t } from "i18next";

export const validateInputText = (
  name,
  value,
  required,
  minSize,
  maxSize,
  errors
) => {
  if (required && !value) {
    errors[name] = t("Must not be empty!");
  } else if (value.length >= 1 && minSize && value.length < minSize) {
    errors[name] = t("Minimum value characters!", { value: minSize });
  } else if (maxSize && value.length > maxSize) {
    errors[name] = t("Maximum value characters!", { value: maxSize });
  }
};

export const validateInputNumber = (
  name,
  value,
  required,
  minValue,
  maxValue,
  errors
) => {
  if (required && value !== 0 && !value) {
    errors[name] = t("Must not be empty!");
  } else if ((minValue === 0 || minValue) && value < minValue) {
    errors[name] = t("Minimum value!", { value: minValue });
  } else if (maxValue && value > maxValue) {
    errors[name] = t("Maximum value!", { value: maxValue });
  }
};

export const validateDropdown = (name, value, required, errors) => {
  if (required && !value) {
    errors[name] = t("Must not be empty!");
  }
};

export const validateEmail = (name, value, required, errors) => {
  if (required && !value) {
    errors[name] = t("Must not be empty!");
  }
  if (
    !value.match(
      "^[\\w!#$%&’*+/=?`{|}~^-]+(?:\\.[\\w!#$%&’*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$"
    )
  ) {
    errors[name] = t("Value format is not valid!", {
      value: t("Email"),
    });
  }
};

export const validateMobileNumber = (name, value, required, errors) => {
  if (required && !value) {
    errors[name] = t("Must not be empty!");
  }
  if (!value.match("^\\+905[0-9]{9}$")) {
    errors[name] = t("Value format is not valid!", {
      value: t("Mobile Number"),
    });
  }
};
