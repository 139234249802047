import { useEffect, useState } from "react";
import { getQrMenuDetails } from "../apis/qrMenuBffApis";
import useErrorHandler from "../hooks/useErrorHandler";
import { useParams } from "react-router-dom";
import { formatTL } from "../common/CommonUtils";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { t } from "i18next";
import { Chip } from "primereact/chip";

const QrMenuPage = (props) => {
  const { previewMode } = props;

  const params = useParams();

  const tenantCode = previewMode ? props.tenantCode : params.tenantCode;

  const [qrMenuDetails, setQrMenuDetails] = useState({
    categoryList: [],
    productList: [],
    qrMenuSettings: {},
  });
  const [loadingGetQrMenuDetails, setLoadingGetQrMenuDetails] = useState(true);

  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchQrMenuDetails = async () => {
      try {
        const response = await getQrMenuDetails(tenantCode);
        setQrMenuDetails(response.data);
      } catch (error) {
        handleError(error);
      }

      setLoadingGetQrMenuDetails(false);
    };

    fetchQrMenuDetails();
  }, []);

  const parentCategoryIdAndCategoryListMap = Object.groupBy(
    qrMenuDetails.categoryList,
    ({ parentCategoryId }) => parentCategoryId
  );

  const categoryIdAndProductMap = Object.groupBy(
    qrMenuDetails.productList,
    ({ categoryId }) => categoryId
  );

  return (
    !loadingGetQrMenuDetails && (
      <div className="grid">
        <div className="col-12 flex justify-content-center">
          <img
            className="max-h-7rem xl:max-h-8rem"
            src={`/images/tenants/${tenantCode}/qr-menu/logo/${qrMenuDetails.qrMenuSettings.logoImageFileName}`}
            alt="logo"
          />
        </div>
        <div className="col-12">
          <TabView>
            {parentCategoryIdAndCategoryListMap[null].map((parentCategory) => (
              <TabPanel
                key={parentCategory.id}
                header={<Chip className="m-0" label={parentCategory.name} />}
                pt={{ headerAction: { className: "p-1" } }}
              >
                <TabView>
                  {parentCategoryIdAndCategoryListMap[parentCategory.id] &&
                    parentCategoryIdAndCategoryListMap[parentCategory.id].map(
                      (childCategory) => (
                        <TabPanel
                          key={childCategory.id}
                          header={
                            <Chip className="m-0" label={childCategory.name} />
                          }
                          pt={{ headerAction: { className: "p-1" } }}
                        >
                          <DataTable
                            pt={{
                              column: {
                                headerCell: { className: "p-2" },
                                bodyCell: { className: "p-2" },
                              },
                            }}
                            showGridlines
                            value={
                              categoryIdAndProductMap[childCategory.id]
                                ? categoryIdAndProductMap[childCategory.id]
                                : []
                            }
                          >
                            <Column
                              className="w-full"
                              header={t("Value Name", {
                                value: t("Product"),
                              })}
                              body={(product) => (
                                <div>
                                  {product.name}
                                  <br />
                                  <span className="text-sm text-600">
                                    {product.description}
                                  </span>
                                </div>
                              )}
                            />
                            <Column
                              field="price"
                              header={t("Price")}
                              body={(product) => formatTL(product.price)}
                            />
                            <Column
                              align="center"
                              header={t("Image")}
                              body={(product) =>
                                product.imageFileName ? (
                                  <img
                                    className="max-h-7rem xl:max-h-8rem"
                                    src={`/images/tenants/${tenantCode}/products/${product.imageFileName}`}
                                    alt={product.name}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </DataTable>
                        </TabPanel>
                      )
                    )}
                </TabView>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    )
  );
};

export default QrMenuPage;
