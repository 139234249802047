import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Button } from "primereact/button";
import ProductForm from "../../components/forms/ProductForm";

const ProductCreatePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        className="p-1"
        label={`< ${t("List Value", { value: t("Product") })}`}
        link
        onClick={() => {
          navigate("/products/list");
        }}
      />
      <ProductForm action="CREATE" />
    </>
  );
};

export default ProductCreatePage;
