import { atom } from "recoil";

export const authorizationTokenState = atom({
  key: "authorization-token",
  default: JSON.parse(localStorage.getItem("authorization-token")),
});

export const languageState = atom({
  key: "language",
  default: localStorage.getItem("language"),
});
