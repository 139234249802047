import { useNavigate } from "react-router-dom";
import TableForm from "../../components/forms/TableForm";
import { t } from "i18next";
import { Button } from "primereact/button";

const TableCreatePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        className="p-1"
        label={`< ${t("List Value", { value: t("Table") })}`}
        link
        onClick={() => {
          navigate("/tables/list");
        }}
      />
      <TableForm action="CREATE" />
    </>
  );
};

export default TableCreatePage;
