import dayjs from "dayjs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { searchListTables } from "../../apis/tableApis";
import { searchPageOrders } from "../../apis/orderApis";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  validateAndGetDateParam,
  validateAndGetIdParam,
  validateAndGetMultipleSelectParam,
} from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { t } from "i18next";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const OrderListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tables, setTables] = useState([]);
  const [orderPage, setOrderPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });

  const [loadingSearchListTables, setLoadingSearchListTables] = useState(true);
  const [loadingSearchPageOrders, setLoadingSearchPageOrders] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const tableMap = new Map();
  tables.forEach((table) => {
    tableMap.set(table.id, table);
  });

  const pageParam = searchParams.get("page");
  const createdDateTimeMinParam = searchParams.get("createdDateTimeMin");
  const createdDateTimeMaxParam = searchParams.get("createdDateTimeMax");
  const tableIdParam = searchParams.get("tableId");
  const statusListParam = searchParams.get("statusList");

  const createdDateTimeMin = validateAndGetDateParam(createdDateTimeMinParam);
  const createdDateTimeMax = validateAndGetDateParam(createdDateTimeMaxParam);
  const tableId = validateAndGetIdParam(tableIdParam, tableMap);
  const statusList = validateAndGetMultipleSelectParam(
    statusListParam,
    ["OPEN", "CLOSE"],
    ["OPEN", "CLOSE"]
  );

  const readyForSearchOrders = !tableIdParam || !loadingSearchListTables;

  useEffect(() => {
    const fetchTables = async () => {
      const tableSearchDTO = {
        statusList: ["ACTIVE", "PASSIVE"],
      };
      try {
        const response = await searchListTables(tableSearchDTO);
        setTables(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListTables(false);
    };

    fetchTables();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      const orderSearchDTO = {
        createdDateTimeMin: createdDateTimeMin
          ? moment(createdDateTimeMin).format("YYYY-MM-DD HH:mm:ss")
          : null,
        createdDateTimeMax: createdDateTimeMax
          ? moment(createdDateTimeMax).format("YYYY-MM-DD HH:mm:ss")
          : null,
        statusList,
        tableId,
      };
      setLoadingSearchPageOrders(true);
      try {
        const response = await searchPageOrders(
          pageParam - 1,
          20,
          orderSearchDTO
        );
        setOrderPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchPageOrders(false);
    };

    if (readyForSearchOrders) {
      fetchOrders();
    }
  }, [
    readyForSearchOrders,
    pageParam,
    createdDateTimeMinParam,
    createdDateTimeMaxParam,
    tableIdParam,
    statusListParam,
  ]);

  const onPage = (event) => {
    searchParams.set("page", event.page + 1);
    setSearchParams(searchParams);
  };

  const onFilter = (event) => {
    if (event.filters.tableId.value) {
      searchParams.set("tableId", event.filters.tableId.value);
    } else {
      searchParams.delete("tableId");
    }

    if (event.filters.createdDateTime.value) {
      if (event.filters.createdDateTime.value[0]) {
        searchParams.set(
          "createdDateTimeMin",
          moment(event.filters.createdDateTime.value[0]).valueOf()
        );
      }

      if (
        event.filters.statusList.value !== null &&
        event.filters.statusList.value.length !== 0
      ) {
        searchParams.set("statusList", event.filters.statusList.value);
      } else {
        searchParams.delete("statusList");
      }

      if (event.filters.createdDateTime.value[1]) {
        searchParams.set(
          "createdDateTimeMax",
          moment(event.filters.createdDateTime.value[1]).valueOf()
        );
      }
    } else {
      searchParams.delete("createdDateTimeMin");
      searchParams.delete("createdDateTimeMax");
    }

    setSearchParams(searchParams);
  };

  return (
    readyForSearchOrders && (
      <DataTable
        showGridlines
        value={orderPage.content}
        lazy
        dataKey="id"
        paginator
        first={pageParam ? (pageParam - 1) * 20 : 0}
        rows={20}
        totalRecords={orderPage.totalElements}
        onPage={onPage}
        onFilter={onFilter}
        filters={{
          tableId: {
            value: tableId,
            matchMode: FilterMatchMode.EQUALS,
          },
          statusList: {
            value: statusList,
            matchMode: FilterMatchMode.IN,
          },
          createdDateTime: {
            value: [createdDateTimeMin, createdDateTimeMax],
            matchMode: FilterMatchMode.BETWEEN,
          },
        }}
        loading={loadingSearchPageOrders || loadingSearchListTables}
      >
        <Column
          className="w-full"
          pt={{ filterInput: { style: { minWidth: "100px" } } }}
          field="tableId"
          header={t("Value Name", { value: t("Table") })}
          filter
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={
                // {
                //   label: `${t("Takeaway")}|${t("Delivery")}`,
                //   value: null,
                // },
                tables.map((table) => ({
                  label: table.name,
                  value: table.id,
                }))
              }
              onChange={(e) => {
                options.filterCallback(e.value);
              }}
            />
          )}
          showFilterMatchModes={false}
          body={(order) => {
            return order.tableName
              ? order.tableName
              : order.type === "TAKEAWAY"
              ? t("Takeaway")
              : t("Delivery");
          }}
        />
        <Column
          field="statusList"
          header={t("Status")}
          filter
          filterElement={(options) => (
            <MultiSelect
              className=""
              value={options.value}
              options={[
                { label: t("Open"), value: "OPEN" },
                { label: t("Close"), value: "CLOSE" },
              ]}
              onChange={(e) => options.filterCallback(e.value)}
            />
          )}
          showFilterMatchModes={false}
          body={(product) =>
            product.status === "OPEN" ? t("Open") : t("Close")
          }
        />
        <Column
          field="createdDateTime"
          header={t("Created Date")}
          dataType="date"
          filter
          filterElement={(options) => {
            const [min, max] = options.value ?? [null, null];

            return (
              <div className="flex flex-column gap-1">
                <Dropdown
                  value="BETWEEN"
                  options={[
                    {
                      label: "BETWEEN",
                      value: "BETWEEN",
                    },
                  ]}
                />
                <label>{t("Start Date")}</label>
                <Calendar
                  value={min}
                  onChange={(e) => options.filterCallback([e.value, max])}
                  showTime
                  hourFormat="24"
                />
                <label>{t("End Date")}</label>
                <Calendar
                  value={max}
                  onChange={(e) => options.filterCallback([min, e.value])}
                  showTime
                  hourFormat="24"
                />
              </div>
            );
          }}
          showFilterMatchModes={false}
          body={(product) =>
            moment(product.createdDateTime).format("DD.MM.YYYY HH:mm")
          }
        />
        <Column
          header={t("Actions")}
          body={(product) => (
            <div className="flex flex-row">
              <Button
                size="small"
                className="p-1"
                link
                label={t("Details")}
                onClick={() => {
                  navigate(`/orders/${product.id}`);
                }}
              />
            </div>
          )}
        />
      </DataTable>
    )
  );
};

export default OrderListPage;
