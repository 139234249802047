import axios from "axios";

export const createOrderProduct = (orderId, body) => {
  return axios.post(`/api/1.0/orders/${orderId}/order-products`, body);
};

export const searchListOrderProducts = (orderProductSearchDTO) => {
  const { orderIdList, orderStatus } = orderProductSearchDTO;

  const params = new URLSearchParams();

  if (orderIdList) {
    params.append("orderIdList", orderIdList);
  }
  if (orderStatus) {
    params.append("orderStatus", orderStatus);
  }

  return axios.get(`/api/1.0/order-products/list?${params.toString()}`);
};

export const updateOrderProduct = (orderProductId, body) => {
  return axios.put(`/api/1.0/order-products/${orderProductId}`, body);
};

export const deleteOrderProduct = (orderProductId) => {
  return axios.delete(`/api/1.0/order-products/${orderProductId}`);
};
