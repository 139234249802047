import { useEffect, useState } from "react";
import { getResetPasswordToken } from "../apis/resetPasswordTokenApis";
import { useNavigate, useParams } from "react-router-dom";
import { updatePassword } from "../apis/userApis";
import { t } from "i18next";
import { Button } from "primereact/button";
import Result from "../components/common/Result";
import useErrorHandler from "../hooks/useErrorHandler";
import P002Password from "../components/input/P002Password";
import FormWrapper from "../components/common/FormWrapper";
import { validateInputText } from "../common/FormValidationUtils";
import { useFormik } from "formik";

const UpdatePasswordPage = () => {
  const { token } = useParams();

  const [loadingGetResetPasswordToken, setLoadingGetResetPasswordToken] =
    useState(true);
  const [loadingUpdatePassword, setLoadingUpdatePassword] = useState(false);

  const [result, setResult] = useState(null);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchResetPasswordToken = async () => {
      try {
        await getResetPasswordToken(token);
      } catch (error) {
        if (error.response.status === 404) {
          navigate("/login");
        } else {
          handleError(error);
        }
      }
      setLoadingGetResetPasswordToken(false);
    };

    fetchResetPasswordToken();
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
    },

    validate: (values) => {
      let errors = {};

      validateInputText("password", values.password, true, 6, 20, errors);

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        token,
        password: values.password,
      };
      setLoadingUpdatePassword(true);
      try {
        await updatePassword(body);
        setResult("SUCCESS");
      } catch (error) {
        setResult("FAIL");
      }
      setLoadingUpdatePassword(false);
    },
  });

  return (
    !loadingGetResetPasswordToken &&
    (result === null ? (
      <div className="flex justify-content-center align-items-center">
        <div className="mt-8 max-w-30rem">
          <FormWrapper title={t("Reset Password")}>
            <form className="grid" onSubmit={formik.handleSubmit}>
              <P002Password
                formik={formik}
                name="password"
                label={t("Password")}
                rootCol={12}
                inputCol={12}
                required
                iconClassName="pi pi-key"
              />
              <div className="col-12">
                <Button
                  type="submit"
                  label={t("Update")}
                  loading={loadingUpdatePassword}
                />
              </div>
              <div className="col-12">
                <Button
                  className="p-1"
                  label={`< ${t("Login")}`}
                  link
                  onClick={() => {
                    navigate("/login");
                  }}
                />
              </div>
            </form>
          </FormWrapper>
        </div>
      </div>
    ) : (
      <Result
        type={result}
        title={
          result === "SUCCESS"
            ? t("Value updated.", { value: t("Password") })
            : "Oops!"
        }
        subTitle={
          result === "SUCCESS"
            ? null
            : t("Something went wrong. Please contact with developer.")
        }
        extra={
          <Button
            className="p-1"
            label={`< ${t("Go Login")}`}
            link
            onClick={() => {
              navigate("/login");
            }}
          />
        }
      />
    ))
  );
};

export default UpdatePasswordPage;
