import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchUsers } from "../../apis/userApis";
import { validateAndGetTextParam } from "../../common/CommonUtils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { t } from "i18next";
import { FilterMatchMode } from "primereact/api";

const UserList = (props) => {
  const { tenantId } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [userPage, setUserPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });

  const [loadingSearchUsers, setLoadingSearchUsers] = useState(false);

  const handleError = useErrorHandler();

  const pageParam = searchParams.get("page");
  const firstNameParam = searchParams.get("firstName");
  const lastNameParam = searchParams.get("lastName");
  const firstName = validateAndGetTextParam(firstNameParam, 40);
  const lastName = validateAndGetTextParam(lastNameParam, 40);

  useEffect(() => {
    const fetchUsers = async () => {
      const userSearchDTO = {
        firstName,
        lastName,
      };
      setLoadingSearchUsers(true);
      try {
        const response = await searchUsers(
          pageParam - 1,
          20,
          userSearchDTO,
          tenantId
        );
        setUserPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchUsers(false);
    };

    fetchUsers();
  }, [pageParam, firstNameParam, lastNameParam]);

  const onPage = (event) => {
    searchParams.set("page", event.page + 1);
    setSearchParams(searchParams);
  };

  const onFilter = (event) => {
    if (event.filters.firstName.value) {
      searchParams.set("firstName", event.filters.firstName.value);
    } else {
      searchParams.delete("firstName");
    }

    if (event.filters.lastName.value) {
      searchParams.set("lastName", event.filters.lastName.value);
    } else {
      searchParams.delete("lastName");
    }

    setSearchParams(searchParams);
  };

  return (
    <DataTable
      showGridlines
      value={userPage.content}
      lazy
      dataKey="id"
      paginator
      first={pageParam ? (pageParam - 1) * 20 : 0}
      rows={20}
      totalRecords={userPage.totalElements}
      onPage={onPage}
      onFilter={onFilter}
      filters={{
        firstName: {
          value: firstName,
          matchMode: FilterMatchMode.CONTAINS,
        },
        lastName: {
          value: lastName,
          matchMode: FilterMatchMode.CONTAINS,
        },
      }}
      loading={loadingSearchUsers}
    >
      <Column
        pt={{ filterInput: { style: { minWidth: "100px" } } }}
        field="firstName"
        header={t("First Name")}
        filter
        filterPlaceholder="Search"
        showFilterMatchModes={false}
      />
      <Column
        pt={{ filterInput: { style: { minWidth: "100px" } } }}
        field="lastName"
        header={t("Last Name")}
        filter
        filterPlaceholder="Search"
        showFilterMatchModes={false}
      />
      <Column field="username" header={t("Username")} />
      <Column field="contactEmail" header={t("Contact Email")} />
      <Column field="contactNumber" header={t("Contact Number")} />
    </DataTable>
  );
};

export default UserList;
