import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { getTenant } from "../../apis/tenantApis";
import { isNumber } from "../../common/CommonUtils";
import { useToast } from "../../components/common/ToastProvider";
import UserForm from "../../components/forms/UserForm";
import { Button } from "primereact/button";
import { t } from "i18next";
import { Panel } from "primereact/panel";

const UserCreatePage = () => {
  const { tenantId } = useParams();

  const [tenant, setTenant] = useState({});

  const [loadingGetTenant, setLoadingGetTenant] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const { showError } = useToast();

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const response = await getTenant(tenantId);
        setTenant(response.data);
        setLoadingGetTenant(false);
      } catch (error) {
        handleError(error);
        navigate("/tenants/list");
      }
    };

    if (!tenantId || isNumber(tenantId)) {
      fetchTenant();
    } else {
      showError("Not valid id");
      navigate("/tenants/list");
    }
  }, []);

  return (
    !loadingGetTenant && (
      <div className="grid">
        <div className="col-12">
          <Panel header="Tenant Info">
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-4">
                <b>Tenant Name: </b>
                {tenant.name}
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-12">
          <Button
            className="p-1"
            label={`< ${t("List Value", { value: t("User") })}`}
            link
            onClick={() => {
              navigate(
                tenantId ? `/tenants/${tenantId}/users/list` : "/users/list"
              );
            }}
          />
          <UserForm tenantId={tenantId} />
        </div>
      </div>
    )
  );
};

export default UserCreatePage;
