import { formatTL } from "../../common/CommonUtils";
import useErrorHandler from "../../hooks/useErrorHandler";
import { deletePayment } from "../../apis/paymentApis";
import dayjs from "dayjs";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";
import { Button } from "primereact/button";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { t } from "i18next";
import { useToast } from "../common/ToastProvider";

const PaymentsTable = (props) => {
  const { readOnly, payments, setPayments } = props;

  const paidPrice =
    payments.reduce((sum, payment) => sum + payment.amount * 100, 0) / 100;

  const [loadingDeletePayment, setLoadingDeletePayment] = useState({});

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const onClickYes = async (payment) => {
    setLoadingDeletePayment((prevState) => ({
      ...prevState,
      [payment.id]: true,
    }));
    try {
      await deletePayment(payment.id);
      showInfo(t("Value deleted.", { value: t("Payment") }));
      setPayments((prevState) =>
        prevState.filter((prevRecord) => prevRecord.id !== payment.id)
      );
    } catch (error) {
      handleError(error);
    }
    setLoadingDeletePayment((prevState) => ({
      ...prevState,
      [payment.id]: false,
    }));
  };

  return (
    <>
      <DataTable
        size="small"
        value={payments}
        showHeaders
        showGridlines
        footerColumnGroup={
          <ColumnGroup>
            <Row>
              <Column colSpan={2} footer={t("Total")}></Column>
              <Column footer={formatTL(paidPrice)}></Column>
            </Row>
          </ColumnGroup>
        }
      >
        <Column
          header={t("Type")}
          body={(payment) =>
            payment.type === "CASH" ? (
              <>
                <i className="pi pi-turkish-lira" /> {t("Cash")}
              </>
            ) : (
              <>
                <i className="pi pi-credit-card" /> {t("Credit Card")}
              </>
            )
          }
        />
        <Column
          header={t("Created Date")}
          body={(payment) =>
            dayjs(payment.createdDateTime).format("DD.MM.YYYY HH:mm")
          }
        />
        <Column
          header={t("Amount")}
          body={(payment) => formatTL(payment.amount)}
        />
        {!readOnly && (
          <Column
            header={t("Actions")}
            body={(payment) => (
              <>
                <Button
                  className="text-primary cursor-pointer p-0"
                  label={t("Delete")}
                  loading={loadingDeletePayment[payment.id]}
                  text
                  onClick={(event) => {
                    confirmPopup({
                      target: event.currentTarget,
                      message: t("Are you sure to delete the value?", {
                        value: t("Payment"),
                      }),
                      icon: "pi pi-info-circle",
                      defaultFocus: "reject",
                      acceptClassName: "p-button-danger",
                      acceptLabel: t("Yes"),
                      rejectLabel: t("No"),
                      accept: () => {
                        onClickYes(payment);
                      },
                    });
                  }}
                />
              </>
            )}
          />
        )}
      </DataTable>
      <ConfirmPopup />
    </>
  );
};

export default PaymentsTable;
