import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";

const Result = (props) => {
  const { type, title, subTitle, extra } = props;

  const navigate = useNavigate();

  const header =
    type === "SUCCESS" ? (
      <i className="pi pi-check-circle text-8xl text-green-600"></i>
    ) : (
      <i className="pi pi-times-circle text-8xl text-red-600"></i>
    );

  return (
    <Card
      pt={{
        header: { className: "pt-1 flex justify-content-center" },
        title: { className: "flex justify-content-center" },
        subTitle: { className: "flex justify-content-center" },
        content: { className: "flex justify-content-center" },
      }}
      header={header}
      title={title}
      subTitle={subTitle ? subTitle : null}
    >
      {extra}
    </Card>
  );
};

export default Result;
