import { Dropdown } from "primereact/dropdown";
import FormWrapper from "../common/FormWrapper";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import UploadImage from "../common/UploadImage";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { createProduct, updateProduct } from "../../apis/productApis";
import { useFormik } from "formik";
import {
  validateDropdown,
  validateInputNumber,
  validateInputText,
} from "../../common/FormValidationUtils";
import useErrorHandler from "../../hooks/useErrorHandler";
import { t } from "i18next";
import { searchListCategories } from "../../apis/categoryApis";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import { useToast } from "../common/ToastProvider";

const ProductForm = (props) => {
  const { action, product, setProduct } = props;

  const [categories, setCategories] = useState([]);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingImage, setLoadingImage] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const categoryMap = new Map();
  const parentCategoryIdAndSubCategoriesMap = new Map();
  parentCategoryIdAndSubCategoriesMap.set(null, []);
  categories.forEach((category) => {
    categoryMap.set(category.id, category.name);

    if (
      parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId) ===
      undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        category,
      ]);
    } else {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        ...parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId),
        category,
      ]);
    }

    if (
      category.parentCategoryId === null &&
      parentCategoryIdAndSubCategoriesMap.get(category.id) === undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.id, []);
    }
  });

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingSearchListCategories(true);
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      categoryId: product ? product.categoryId : undefined,
      productName: product ? product.name : "",
      price: product ? product.price : undefined,
      packagedPrice: product ? product.packagedPrice : undefined,
      position: product ? product.position : 0,
      status: product ? product.status : "ACTIVE",
      description: product?.description ? product.description : "",
      image: undefined,
      showInQrMenu: product ? product.showInQrMenu : true,
    },

    validate: (data) => {
      let errors = {};

      validateDropdown("categoryId", data.categoryId, true, errors);
      validateInputText("productName", data.productName, true, 2, 40, errors);
      validateInputNumber("price", data.price, true, 0, 9999.99, errors);
      validateInputNumber(
        "packagedPrice",
        data.packagedPrice,
        true,
        0,
        9999.99,
        errors
      );
      validateInputNumber("position", data.position, true, 0, null, errors);
      validateInputText(
        "description",
        data.description,
        false,
        null,
        250,
        errors
      );

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        categoryId: values.categoryId,
        name: values.productName,
        price: values.price,
        packagedPrice: values.packagedPrice,
        position: values.position,
        status: values.status,
        description: values.description ? values.description : null,
        image: values.image ? values.image.split("base64,")[1] : null,
        showInQrMenu: values.showInQrMenu,
      };

      setLoadingSubmit(true);
      try {
        if (action === "CREATE") {
          await createProduct(body);
          showInfo(t("Value created.", { value: t("Product") }));
          formik.resetForm();
        } else if (action === "UPDATE") {
          const response = await updateProduct(product.id, body);
          setProduct(response.data);
          showInfo(t("Value updated.", { value: t("Product") }));
        }
      } catch (error) {
        handleError(error);
      }
      setLoadingSubmit(false);
    },
  });

  return (
    <FormWrapper
      title={
        action === "CREATE"
          ? t("Value Create Form", { value: t("Product") })
          : action === "UPDATE"
          ? t("Value Update Form", { value: t("Product") })
          : ""
      }
    >
      <form className="grid" onSubmit={formik.handleSubmit}>
        <div className="col-12 flex flex-column">
          <label>{t("Category")}*</label>
          <Dropdown
            className="w-full lg:w-6"
            name="categoryId"
            loading={loadingSearchListCategories}
            value={formik.values.categoryId}
            optionLabel="label"
            optionValue="value"
            options={parentCategoryIdAndSubCategoriesMap
              .get(null)
              .flatMap((parentCategory) =>
                parentCategoryIdAndSubCategoriesMap
                  .get(parentCategory.id)
                  .map((subCategory) => ({
                    label: parentCategory.name + " > " + subCategory.name,
                    value: subCategory.id,
                  }))
              )}
            onChange={formik.handleChange}
            invalid={formik.errors.categoryId}
          />
          {formik.errors.categoryId && (
            <small className="p-error">{formik.errors.categoryId}</small>
          )}
        </div>
        <div className="col-12 flex flex-column">
          <label>{t("Value Name", { value: t("Product") })}*</label>
          <InputText
            className="w-full lg:w-6"
            name="productName"
            value={formik.values.productName}
            onChange={formik.handleChange}
            invalid={formik.errors.productName}
          />
          {formik.errors.productName && (
            <small className="p-error">{formik.errors.productName}</small>
          )}
        </div>
        <div className="col-12 lg:col-6">
          <div className="flex flex-column">
            <label>{t("Product Price")}*</label>
            <InputNumber
              name="price"
              mode="decimal"
              min={0}
              maxFractionDigits={2}
              value={formik.values.price}
              onChange={(e) => {
                formik.setFieldValue("price", e.value);
              }}
              invalid={formik.errors.price}
            />
            {formik.errors.price && (
              <small className="p-error">{formik.errors.price}</small>
            )}
          </div>
        </div>
        <div className="col-12 lg:col-6">
          <div className="flex flex-column">
            <label>{t("Packaged Product Price")}*</label>
            <InputNumber
              name="packagedPrice"
              mode="decimal"
              min={0}
              maxFractionDigits={2}
              value={formik.values.packagedPrice}
              onChange={(e) => {
                formik.setFieldValue("packagedPrice", e.value);
              }}
              invalid={formik.errors.packagedPrice}
            />
            {formik.errors.packagedPrice && (
              <small className="p-error">{formik.errors.packagedPrice}</small>
            )}
          </div>
        </div>
        <div className="col-12 lg:col-6">
          <div className="flex flex-column">
            <label>{t("Position")}*</label>
            <InputNumber
              name="position"
              min={0}
              value={formik.values.position}
              onChange={(e) => {
                formik.setFieldValue("position", e.value);
              }}
              invalid={formik.errors.position}
            />
            {formik.errors.position && (
              <small className="p-error">{formik.errors.position}</small>
            )}
          </div>
        </div>
        <div className="col-12 lg:col-6">
          <div className="flex flex-column">
            <label>{t("Status")}*</label>
            <Dropdown
              name="status"
              loading={loadingSearchListCategories}
              value={formik.values.status}
              optionLabel="label"
              optionValue="value"
              options={[
                { label: t("Active"), value: "ACTIVE" },
                { label: t("Passive"), value: "PASSIVE" },
              ]}
              onChange={formik.handleChange}
              invalid={formik.errors.status}
            />
          </div>
        </div>
        <div className="col-12 flex flex-column">
          <label>{t("Description")}</label>
          <InputText
            className="w-full lg:w-6"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            invalid={formik.errors.description}
          />
          {formik.errors.description && (
            <small className="p-error">{formik.errors.description}</small>
          )}
        </div>
        <div className="col-12 flex flex-column">
          <label>{t("Image")}</label>
          <UploadImage
            formik={formik}
            name="image"
            initialImageUrl={
              product && product.imageFileName
                ? `/images/tenants/${authorizationToken.tenant.code}/products/${product.imageFileName}`
                : undefined
            }
            loading={loadingImage}
            setLoading={setLoadingImage}
          />
        </div>
        <div className="col-12 flex align-items-center">
          <Checkbox
            name="showInQrMenu"
            onChange={formik.handleChange}
            checked={formik.values.showInQrMenu}
          />
          <label className="ml-2 mb-0">{t("Show in QR menu")}</label>
        </div>
        <div className="col-12">
          <Button
            type="submit"
            label={t("Save")}
            loading={
              loadingSubmit || loadingSearchListCategories || loadingImage
            }
          />
        </div>
      </form>
    </FormWrapper>
  );
};

export default ProductForm;
