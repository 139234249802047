import axios from "axios";

export const createProduct = (body) => {
  return axios.post("/api/1.0/products", body);
};

export const getProduct = (productId) => {
  return axios.get(`/api/1.0/products/${productId}`);
};

export const searchPageProducts = (page, size, productSearchDTO) => {
  const { idList, name, statusList, categoryId } = productSearchDTO;

  const params = new URLSearchParams();
  params.append("page", page);
  params.append("size", size);

  if (idList) {
    params.append("idList", idList);
  }
  if (name) {
    params.append("name", name);
  }
  if (statusList) {
    params.append("statusList", statusList);
  }
  if (categoryId) {
    params.append("categoryId", categoryId);
  }

  return axios.get(`/api/1.0/products/page?${params.toString()}`);
};

export const searchListProducts = (productSearchDTO) => {
  const { idList, name, statusList, categoryId } = productSearchDTO;

  const params = new URLSearchParams();

  if (idList) {
    params.append("idList", idList);
  }
  if (name) {
    params.append("name", name);
  }
  if (statusList) {
    params.append("statusList", statusList);
  }
  if (categoryId) {
    params.append("categoryId", categoryId);
  }

  return axios.get(`/api/1.0/products/list?${params.toString()}`);
};

export const updateProduct = (productId, body) => {
  return axios.put(`/api/1.0/products/${productId}`, body);
};

export const deleteProduct = (productId) => {
  return axios.delete(`/api/1.0/products/${productId}`);
};
