import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useState } from "react";

const P002AutoComplete = (props) => {
  const {
    label,
    name,
    formik,
    rootCol,
    rootLgCol,
    inputCol,
    inputLgCol,
    required,
    options,
    disabled,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (formik.values[name]) {
      setSearchText(
        options.find((option) => option.id === formik.values[name]).name
      );
    } else {
      setSearchText("");
    }
  }, [formik.values[name]]);

  return (
    <div
      className={`${rootCol ? `col-${rootCol} p-0` : ""} ${
        rootLgCol ? `lg:col-${rootLgCol}` : ""
      } `}
    >
      <div
        className={`${inputCol ? "col-" + inputCol : ""} 
        ${inputLgCol ? `lg:col-${inputLgCol}` : ""} 
        flex flex-column`}
      >
        <label className="pb-1">
          {required && <span className="text-red-600">* </span>}
          {label}
        </label>
        <AutoComplete
          name={name}
          field="name"
          value={searchText}
          suggestions={filteredList}
          completeMethod={(e) => {
            setFilteredList(
              options.filter((opiton) =>
                opiton.name
                  .toLocaleLowerCase("tr-TR")
                  .includes(e.query.toLocaleLowerCase("tr-TR"))
              )
            );
          }}
          onChange={(e) => {
            formik.setFieldValue(name, undefined);
            setSearchText(e.value);
          }}
          onSelect={(e) => {
            formik.setFieldValue(name, e.value.id);
          }}
          dropdown
          disabled={disabled}
        />
        {formik.errors[name] && (
          <small className="p-error">{formik.errors[name]}</small>
        )}
      </div>
    </div>
  );
};

export default P002AutoComplete;
