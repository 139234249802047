import axios from "axios";

export const createCategory = (body) => {
  return axios.post("/api/1.0/categories", body);
};

export const getCategory = (categoryId) => {
  return axios.get(`/api/1.0/categories/${categoryId}`);
};

export const searchListCategories = () => {
  return axios.get(`/api/1.0/categories/list`);
};

export const updateCategory = (categoryId, body) => {
  return axios.put(`/api/1.0/categories/${categoryId}`, body);
};

export const deleteCategory = (categoryId) => {
  return axios.delete(`/api/1.0/categories/${categoryId}`);
};
