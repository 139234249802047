import axios from "axios";

export const getTrendyolYemekPackagePage = (page, size) => {
  return axios.get(
    `/api/v1/trendyol-yemek/packages/page?page=${page}&size=${size}`
  );
};

export const updateTrendyolYemekPackageStatusToPicked = (packageId) => {
  return axios.put(`/api/v1/trendyol-yemek/packages/${packageId}/picked`);
};

export const updateTrendyolYemekPackageStatusToInvoiced = (packageId) => {
  return axios.put(`/api/v1/trendyol-yemek/packages/${packageId}/invoiced`);
};

export const updateTrendyolYemekPackageStatusToShipped = (packageId) => {
  return axios.put(`/api/v1/trendyol-yemek/packages/${packageId}/shipped`);
};

export const updateTrendyolYemekPackageStatusToDelivered = (packageId) => {
  return axios.put(`/api/v1/trendyol-yemek/packages/${packageId}/delivered`);
};
