import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { deleteTable, searchPageTables } from "../../apis/tableApis";
import {
  validateAndGetMultipleSelectParam,
  validateAndGetTextParam,
} from "../../common/CommonUtils";
import { useToast } from "../../components/common/ToastProvider";
import { t } from "i18next";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const TableListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tablePage, setTablePage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });

  const [loadingSearchPageTables, setLoadingSearchPageTables] = useState(true);
  const [loadingDeleteTable, setLoadingDeleteTable] = useState({});

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const navigate = useNavigate();

  const pageParam = searchParams.get("page");
  const nameParam = searchParams.get("name");
  const statusListParam = searchParams.get("statusList");

  const name = validateAndGetTextParam(nameParam, 10);
  const statusList = validateAndGetMultipleSelectParam(
    statusListParam,
    ["ACTIVE", "PASSIVE"],
    ["ACTIVE", "PASSIVE"]
  );

  useEffect(() => {
    const fetchTables = async () => {
      const tableSearchDTO = {
        name,
        statusList,
      };
      setLoadingSearchPageTables(true);
      try {
        const response = await searchPageTables(
          pageParam - 1,
          20,
          tableSearchDTO
        );
        setTablePage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchPageTables(false);
    };

    fetchTables();
  }, [pageParam, nameParam, statusListParam]);

  const onClickDeleteYes = async (table) => {
    setLoadingDeleteTable((prevState) => ({
      ...prevState,
      [table.id]: true,
    }));
    try {
      await deleteTable(table.id);
      showInfo(t("Value deleted.", { value: table.name }));
      setTablePage((prevState) => ({
        ...prevState,
        content: [...prevState.content.filter((item) => item.id !== table.id)],
      }));
    } catch (error) {
      handleError(error);
    }
    setLoadingDeleteTable((prevState) => ({
      ...prevState,
      [table.id]: false,
    }));
  };

  const onPage = (event) => {
    searchParams.set("page", event.page + 1);
    setSearchParams(searchParams);
  };

  const onFilter = (event) => {
    if (
      event.filters.status.value !== null &&
      event.filters.status.value.length !== 0
    ) {
      searchParams.set("statusList", event.filters.status.value);
    } else {
      searchParams.delete("statusList");
    }

    if (event.filters.name.value) {
      searchParams.set("name", event.filters.name.value);
    } else {
      searchParams.delete("name");
    }

    setSearchParams(searchParams);
  };

  return (
    <div>
      <DataTable
        showGridlines
        value={tablePage.content}
        lazy
        dataKey="id"
        paginator
        first={pageParam ? (pageParam - 1) * 20 : 0}
        rows={20}
        totalRecords={tablePage.totalElements}
        onPage={onPage}
        onFilter={onFilter}
        filters={{
          name: {
            value: name,
            matchMode: FilterMatchMode.CONTAINS,
          },
          status: {
            value: statusList,
            matchMode: FilterMatchMode.IN,
          },
        }}
        loading={loadingSearchPageTables}
      >
        <Column
          className="w-full"
          pt={{ filterInput: { style: { minWidth: "100px" } } }}
          field="name"
          header={t("Name")}
          // sortable
          filter
          filterPlaceholder="Search"
          showFilterMatchModes={false}
        />
        <Column field="capacity" header={t("Capacity")} />
        <Column
          field="status"
          header={t("Status")}
          filter
          filterElement={(options) => (
            <MultiSelect
              value={options.value}
              options={[
                { label: t("Active"), value: "ACTIVE" },
                { label: t("Passive"), value: "PASSIVE" },
              ]}
              onChange={(e) => options.filterCallback(e.value)}
            />
          )}
          showFilterMatchModes={false}
          body={(table) =>
            table.status === "ACTIVE" ? t("Active") : t("Passive")
          }
        />
        <Column field="position" header={t("Position")} />
        <Column
          header={t("Actions")}
          body={(table) => (
            <div className="flex flex-row">
              <Button
                size="small"
                className="p-1 mr-1"
                link
                label={t("Update")}
                disabled={loadingDeleteTable[table.id]}
                onClick={() => {
                  navigate(`/tables/${table.id}/update`);
                }}
              />
              <Button
                size="small"
                className="p-1"
                link
                label={t("Delete")}
                loading={loadingDeleteTable[table.id]}
                onClick={(event) => {
                  confirmPopup({
                    target: event.currentTarget,
                    message: t("Are you sure to delete the value?", {
                      value: t("Table"),
                    }),
                    icon: "pi pi-info-circle",
                    defaultFocus: "reject",
                    acceptClassName: "p-button-danger",
                    acceptLabel: t("Yes"),
                    rejectLabel: t("No"),
                    accept: () => {
                      onClickDeleteYes(table);
                    },
                  });
                }}
              />
            </div>
          )}
        />
      </DataTable>
      <ConfirmPopup />
    </div>
  );
};

export default TableListPage;
