import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListCategories } from "../../apis/categoryApis";
import { deleteProduct, searchPageProducts } from "../../apis/productApis";
import {
  formatTL,
  validateAndGetIdParam,
  validateAndGetMultipleSelectParam,
  validateAndGetTextParam,
} from "../../common/CommonUtils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { t } from "i18next";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { useToast } from "../../components/common/ToastProvider";

const ProductListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [categories, setCategories] = useState([]);
  const [productPage, setProductPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingSearchPageProducts, setLoadingSearchPageProducts] =
    useState(false);
  const [loadingDeleteProduct, setLoadingDeleteProduct] = useState({});

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const navigate = useNavigate();

  const categoryMap = new Map();
  const childCategoryMap = new Map();
  const parentCategoryIdAndSubCategoriesMap = new Map();
  parentCategoryIdAndSubCategoriesMap.set(null, []);
  categories.forEach((category) => {
    categoryMap.set(category.id, category);

    if (category.parentCategoryId !== null) {
      childCategoryMap.set(category.id, category);
    }

    if (
      parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId) ===
      undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        category,
      ]);
    } else {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        ...parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId),
        category,
      ]);
    }

    if (
      category.parentCategoryId === null &&
      parentCategoryIdAndSubCategoriesMap.get(category.id) === undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.id, []);
    }
  });

  const pageParam = searchParams.get("page");
  const categoryIdParam = searchParams.get("categoryId");
  const nameParam = searchParams.get("name");
  const statusListParam = searchParams.get("statusList");

  const categoryId = validateAndGetIdParam(categoryIdParam, childCategoryMap);
  const name = validateAndGetTextParam(nameParam, 40);
  const statusList = validateAndGetMultipleSelectParam(
    statusListParam,
    ["ACTIVE", "PASSIVE"],
    ["ACTIVE", "PASSIVE"]
  );

  const readyForSearchProducts = !loadingSearchListCategories;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const productSearchDTO = {
        name,
        categoryId,
        statusList,
      };
      setLoadingSearchPageProducts(true);
      try {
        const response = await searchPageProducts(
          pageParam - 1,
          20,
          productSearchDTO
        );
        setProductPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchPageProducts(false);
    };

    if (readyForSearchProducts) {
      fetchProducts();
    }
  }, [
    readyForSearchProducts,
    pageParam,
    categoryIdParam,
    nameParam,
    statusListParam,
  ]);

  const onClickDeleteYes = async (product) => {
    setLoadingDeleteProduct((prevState) => ({
      ...prevState,
      [product.id]: true,
    }));
    try {
      await deleteProduct(product.id);
      showInfo(t("Value deleted.", { value: product.name }));
      setProductPage((prevState) => ({
        ...prevState,
        content: [
          ...prevState.content.filter((item) => item.id !== product.id),
        ],
      }));
    } catch (error) {
      handleError(error);
    }
    setLoadingDeleteProduct((prevState) => ({
      ...prevState,
      [product.id]: false,
    }));
  };

  const onPage = (event) => {
    searchParams.set("page", event.page + 1);
    setSearchParams(searchParams);
  };

  const onFilter = (event) => {
    if (
      event.filters.status.value !== null &&
      event.filters.status.value.length !== 0
    ) {
      searchParams.set("statusList", event.filters.status.value);
    } else {
      searchParams.delete("statusList");
    }

    if (event.filters.name.value) {
      searchParams.set("name", event.filters.name.value);
    } else {
      searchParams.delete("name");
    }

    if (event.filters.category.value) {
      searchParams.set("categoryId", event.filters.category.value);
    } else {
      searchParams.delete("categoryId");
    }

    setSearchParams(searchParams);
  };

  return (
    !loadingSearchListCategories && (
      <div>
        <DataTable
          showGridlines
          value={productPage.content}
          lazy
          dataKey="id"
          paginator
          first={pageParam ? (pageParam - 1) * 20 : 0}
          rows={20}
          totalRecords={productPage.totalElements}
          onPage={onPage}
          onFilter={onFilter}
          filters={{
            name: {
              value: name,
              matchMode: FilterMatchMode.CONTAINS,
            },
            status: {
              value: statusList,
              matchMode: FilterMatchMode.IN,
            },
            category: {
              value: categoryId,
              matchMode: FilterMatchMode.EQUALS,
            },
          }}
          loading={loadingSearchPageProducts || loadingSearchListCategories}
        >
          <Column
            className="w-full"
            pt={{ filterInput: { style: { minWidth: "100px" } } }}
            field="name"
            header={t("Name")}
            // sortable
            filter
            filterPlaceholder="Search"
            showFilterMatchModes={false}
          />
          <Column
            field="price"
            header={t("Price")}
            body={(product) => formatTL(product.price)}
            // sortable
          />
          <Column
            field="status"
            header={t("Status")}
            filter
            filterElement={(options) => (
              <MultiSelect
                value={options.value}
                options={[
                  { label: t("Active"), value: "ACTIVE" },
                  { label: t("Passive"), value: "PASSIVE" },
                ]}
                onChange={(e) => options.filterCallback(e.value)}
              />
            )}
            showFilterMatchModes={false}
            body={(product) =>
              product.status === "ACTIVE" ? t("Active") : t("Passive")
            }
          />
          <Column
            field="category"
            header={t("Category")}
            showFilterMatchModes={false}
            filter
            filterElement={(options) => (
              <Dropdown
                name="category"
                value={options.value}
                options={parentCategoryIdAndSubCategoriesMap
                  .get(null)
                  .flatMap((parentCategory) =>
                    parentCategoryIdAndSubCategoriesMap
                      .get(parentCategory.id)
                      .map((subCategory) => ({
                        label: parentCategory.name + " > " + subCategory.name,
                        value: subCategory.id,
                      }))
                  )}
                onChange={(e) => {
                  options.filterCallback(e.value);
                }}
              />
            )}
            body={(product) =>
              `${
                categoryMap.get(
                  categoryMap.get(product.categoryId).parentCategoryId
                ).name
              }>${categoryMap.get(product.categoryId).name}`
            }
          />
          <Column field="position" header={t("Position")} />
          <Column
            header={t("Actions")}
            body={(product) => (
              <div className="flex flex-row">
                <Button
                  size="small"
                  className="p-1 mr-1"
                  link
                  label={t("Update")}
                  disabled={loadingDeleteProduct[product.id]}
                  onClick={() => {
                    navigate(`/products/${product.id}/update`);
                  }}
                />
                <Button
                  size="small"
                  className="p-1"
                  link
                  label={t("Delete")}
                  loading={loadingDeleteProduct[product.id]}
                  onClick={(event) => {
                    confirmPopup({
                      target: event.currentTarget,
                      message: t("Are you sure to delete the value?", {
                        value: t("Product"),
                      }),
                      icon: "pi pi-info-circle",
                      defaultFocus: "reject",
                      acceptClassName: "p-button-danger",
                      acceptLabel: t("Yes"),
                      rejectLabel: t("No"),
                      accept: () => {
                        onClickDeleteYes(product);
                      },
                    });
                  }}
                />
              </div>
            )}
          />
        </DataTable>
        <ConfirmPopup />
      </div>
    )
  );
};

export default ProductListPage;
