import { useRecoilValue } from "recoil";
import { authorizationTokenState, languageState } from "./recoil/atoms";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import axios from "axios";
import i18n from "./i18n/i18n";

import LoginPage from "./pages/LoginPage";
import ContentWrapper from "./container/ContentWrapper";
import TenantCreatePage from "./pages/superadmin/TenantCreatePage";
import TenantListPage from "./pages/superadmin/TenantListPage";
import { default as SAUserListPage } from "./pages/superadmin/UserListPage";
import { default as SAUserCreatePage } from "./pages/superadmin/UserCreatePage";
import { default as TAUserListPage } from "./pages/tenantadmin/UserListPage";
import { default as TAUserCreatePage } from "./pages/tenantadmin/UserCreatePage";
import TableListPage from "./pages/tenantadmin/TableListPage";
import TableCreatePage from "./pages/tenantadmin/TableCreatePage";
import TableUpdatePage from "./pages/tenantadmin/TableUpdatePage";
import CategoryListPage from "./pages/tenantadmin/CategoryListPage";
import CategoryCreatePage from "./pages/tenantadmin/CategoryCreatePage";
import CategoryUpdatePage from "./pages/tenantadmin/CategoryUpdatePage";
import ProductUpdatePage from "./pages/tenantadmin/ProductUpdatePage";
import ProductCreatePage from "./pages/tenantadmin/ProductCreatePage";
import ProductListPage from "./pages/tenantadmin/ProductListPage";
import OrderManagementPage from "./pages/tenantadmin/OrderManagementPage";
import OrderListPage from "./pages/tenantadmin/OrderListPage";
import OrderPage from "./pages/tenantadmin/OrderPage";
import StatisticsPage from "./pages/tenantadmin/StatisticsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";
import BillPrintSettingsPage from "./pages/tenantadmin/BillPrintSettingsPage";
import QrMenuSettings from "./pages/tenantadmin/QrMenuSettingsPage";
import QrMenuPage from "./pages/QrMenuPage";
import BalanceManagementPage from "./pages/tenantadmin/BalanceManagementPage";
import TrendyolYemekIntegrationPage from "./pages/tenantadmin/TrendyolYemekIntegrationPage";
import { locale } from "primereact/api";

const App = () => {
  const [readyToRender, setReadyToRender] = useState(false);
  const authorizationToken = useRecoilValue(authorizationTokenState);
  const language = useRecoilValue(languageState);

  useEffect(() => {
    setReadyToRender(false);

    if (authorizationToken) {
      localStorage.setItem(
        "authorization-token",
        JSON.stringify(authorizationToken)
      );
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + authorizationToken.token;
    } else {
      localStorage.setItem("authorization-token", null);
      delete axios.defaults.headers.common["Authorization"];
    }

    setReadyToRender(true);
  }, [authorizationToken]);

  useEffect(() => {
    axios.defaults.headers.common["Accept-Language"] = i18n.language;
  }, [language]);

  const routes = {
    SUPER_ADMIN: (
      <Routes>
        <Route path="/tenants/list" element={<TenantListPage />} />
        <Route path="/tenants/create" element={<TenantCreatePage />} />
        <Route
          path="/tenants/:tenantId/users/list"
          element={<SAUserListPage />}
        />
        <Route
          path="/tenants/:tenantId/users/create"
          element={<SAUserCreatePage />}
        />
        <Route path="*" element={<Navigate to="/tenants/list" replace />} />
      </Routes>
    ),
    TENANT_ADMIN: (
      <Routes>
        <Route path="/users/list" element={<TAUserListPage />} />
        <Route path="/users/create" element={<TAUserCreatePage />} />
        <Route path="/tables/list" element={<TableListPage />} />
        <Route path="/tables/create" element={<TableCreatePage />} />
        <Route path="/tables/:tableId/update" element={<TableUpdatePage />} />
        <Route path="/categories/list" element={<CategoryListPage />} />
        <Route path="/categories/create" element={<CategoryCreatePage />} />
        <Route
          path="/categories/:categoryId/update"
          element={<CategoryUpdatePage />}
        />
        <Route path="/products/list" element={<ProductListPage />} />
        <Route path="/products/create" element={<ProductCreatePage />} />
        <Route
          path="/products/:productId/update"
          element={<ProductUpdatePage />}
        />
        <Route path="/order-management" element={<OrderManagementPage />} />
        <Route path="/orders/list" element={<OrderListPage />} />
        <Route path="/orders/:orderId" element={<OrderPage />} />
        <Route path="/statistics" element={<StatisticsPage />} />
        <Route
          path="/bill-print-settings"
          element={<BillPrintSettingsPage />}
        />
        <Route path="/qr-menu-settings" element={<QrMenuSettings />} />
        <Route path="/:tenantCode/qr-menu" element={<QrMenuPage />} />

        <Route path="/balance-management" element={<BalanceManagementPage />} />
        <Route
          path="/integrations/trendyol-yemek"
          element={<TrendyolYemekIntegrationPage />}
        />
        <Route path="*" element={<Navigate to="/order-management" replace />} />
      </Routes>
    ),
  };

  return (
    readyToRender && (
      <BrowserRouter>
        <ContentWrapper>
          {authorizationToken ? (
            routes[authorizationToken.user.role]
          ) : (
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route
                path="/reset-password/:token"
                element={<UpdatePasswordPage />}
              />
              <Route path="/:tenantCode/qr-menu" element={<QrMenuPage />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          )}
        </ContentWrapper>
      </BrowserRouter>
    )
  );
};

export default App;
