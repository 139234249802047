import { Toast } from "primereact/toast";
import React, { createContext, useContext, useRef } from "react";

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);

  const showInfo = (message) =>
    toast.current.show({
      severity: "info",
      detail: message,
      closable: false,
    });

  const showError = (message) =>
    toast.current.show({
      severity: "error",
      detail: message,
      closable: false,
    });

  return (
    <ToastContext.Provider value={{ toast, showInfo, showError }}>
      {children}
      <Toast ref={toast} pt={{ content: { className: "p-2" } }} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
