import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTable } from "../../apis/tableApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import { isNumber } from "../../common/CommonUtils";
import { useToast } from "../../components/common/ToastProvider";
import { t } from "i18next";
import { Button } from "primereact/button";
import TableForm from "../../components/forms/TableForm";

const TableUpdatePage = () => {
  const { tableId } = useParams();

  const [table, setTable] = useState({});

  const [loadingGetTable, setLoadingGetTable] = useState(true);

  const handleError = useErrorHandler();

  const { showError } = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTable = async () => {
      try {
        const response = await getTable(tableId);
        setTable(response.data);
        setLoadingGetTable(false);
      } catch (error) {
        handleError(error);
        navigate("/tables/list");
      }
    };

    if (isNumber(tableId)) {
      fetchTable();
    } else {
      showError("Not valid id");
      navigate("/tables/list");
    }
  }, []);

  return (
    !loadingGetTable && (
      <>
        <Button
          className="p-1"
          label={`< ${t("List Value", { value: t("Table") })}`}
          link
          onClick={() => {
            navigate("/tables/list");
          }}
        />
        <TableForm action="UPDATE" table={table} setTable={setTable} />
      </>
    )
  );
};

export default TableUpdatePage;
