import { useFormik } from "formik";
import FormWrapper from "../common/FormWrapper";
import P002InputNumber from "../input/P002InputNumber";
import P002InputText from "../input/P002InputText";
import { t } from "i18next";
import { Button } from "primereact/button";
import P002Dropdown from "../input/P002Dropdown";
import { createTable, updateTable } from "../../apis/tableApis";
import {
  validateDropdown,
  validateInputNumber,
  validateInputText,
} from "../../common/FormValidationUtils";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useToast } from "../common/ToastProvider";

const TableForm = (props) => {
  const { action, table, setTable } = props;

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const formik = useFormik({
    initialValues: {
      tableName: table ? table.name : "",
      capacity: table ? table.capacity : undefined,
      position: table ? table.position : 0,
      status: table ? table.status : "ACTIVE",
    },

    validate: (values) => {
      let errors = {};

      validateInputText("tableName", values.tableName, true, 1, 10, errors);
      validateInputNumber("capacity", values.capacity, true, 1, null, errors);
      validateInputNumber("position", values.position, true, 0, null, errors);
      validateDropdown("status", values.status, true, errors);

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        name: values.tableName,
        capacity: values.capacity,
        position: values.position,
        status: values.status,
      };

      setLoadingSubmit(true);
      try {
        if (action === "CREATE") {
          await createTable(body);
          showInfo(t("Value created.", { value: t("Table") }));
          formik.resetForm();
        } else if (action === "UPDATE") {
          const response = await updateTable(table.id, body);
          setTable(response.data);
          showInfo(t("Value updated.", { value: t("Table") }));
        }
      } catch (error) {
        handleError(error);
      }
      setLoadingSubmit(false);
    },
  });

  return (
    <FormWrapper
      title={
        action === "CREATE"
          ? t("Value Create Form", { value: t("Table") })
          : action === "UPDATE"
          ? t("Value Update Form", { value: t("Table") })
          : ""
      }
    >
      <form className="grid" onSubmit={formik.handleSubmit}>
        <P002InputText
          label={t("Value Name", { value: t("Table") })}
          name="tableName"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputNumber
          label={t("Table Capacity")}
          name="capacity"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputNumber
          label={t("Value Position", { value: t("Table") })}
          name="position"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002Dropdown
          label={t("Status")}
          name="status"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
          options={[
            { label: t("Active"), value: "ACTIVE" },
            { label: t("Passive"), value: "PASSIVE" },
          ]}
        />

        <div className="col-12">
          <Button type="submit" label={t("Save")} loading={loadingSubmit} />
        </div>
      </form>
    </FormWrapper>
  );
};

export default TableForm;
