import { useState } from "react";
import { createResetPasswordToken } from "../apis/resetPasswordTokenApis";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { t } from "i18next";
import P002InputText from "../components/input/P002InputText";
import { validateInputText } from "../common/FormValidationUtils";
import FormWrapper from "../components/common/FormWrapper";
import { Button } from "primereact/button";
import Result from "../components/common/Result";

const ResetPasswordPage = () => {
  const [loadingCreateResetPasswordToken, setLoadingCreateResetPasswordToken] =
    useState(false);

  const [result, setResult] = useState(null);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
    },

    validate: (values) => {
      let errors = {};

      validateInputText("username", values.username, true, 4, 20, errors);

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        username: values.username,
      };
      setLoadingCreateResetPasswordToken(true);
      try {
        await createResetPasswordToken(body);
        setResult("SUCCESS");
      } catch (error) {
        setResult("FAIL");
      }
      setLoadingCreateResetPasswordToken(false);
    },
  });

  return result === null ? (
    <div className="flex justify-content-center align-items-center">
      <div className="mt-8 max-w-30rem">
        <FormWrapper title={t("Reset Password")}>
          <form className="grid" onSubmit={formik.handleSubmit}>
            <P002InputText
              formik={formik}
              name="username"
              label={t("Username")}
              rootCol={12}
              inputCol={12}
              required
              iconClassName="pi pi-user"
            />
            <div className="col-12">
              <Button
                type="submit"
                label={t("Reset Password")}
                loading={loadingCreateResetPasswordToken}
              />
            </div>
            <div className="col-12">
              <Button
                className="p-1"
                label={`< ${t("Login")}`}
                link
                onClick={() => {
                  navigate("/login");
                }}
              />
            </div>
          </form>
        </FormWrapper>
      </div>
    </div>
  ) : (
    <Result
      type={result}
      title={result === "SUCCESS" ? t("Email sent.") : "Oops!"}
      subTitle={
        result === "SUCCESS"
          ? t("Please continue from the link we sent to your email.")
          : t("Something went wrong. Please contact with developer.")
      }
      extra={
        <Button
          className="p-1"
          label={`< ${t("Go Login")}`}
          link
          onClick={() => {
            navigate("/login");
          }}
        />
      }
    />
  );
};

export default ResetPasswordPage;
