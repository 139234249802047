import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { createTenant } from "../../apis/tenantApis";
import { useToast } from "../../components/common/ToastProvider";
import FormWrapper from "../../components/common/FormWrapper";
import P002InputText from "../../components/input/P002InputText";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { validateInputText } from "../../common/FormValidationUtils";
import { t } from "i18next";

const TenantCreatePage = () => {
  const [loadingCreateTenant, setLoadingCreateTenant] = useState(false);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      tenantName: "",
      tenantCode: "",
      address: "",
      city: "",
      district: "",
    },

    validate: (values) => {
      let errors = {};

      validateInputText(
        "tenantName",
        values.categoryName,
        true,
        null,
        100,
        errors
      );
      validateInputText(
        "tenantCode",
        values.tenantCode,
        true,
        null,
        100,
        errors
      );
      validateInputText("address", values.address, true, null, 250, errors);
      validateInputText("city", values.city, true, null, 30, errors);
      validateInputText("district", values.district, true, null, 30, errors);

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        name: values.tenantName,
        code: values.tenantCode,
        address: values.address,
        city: values.city,
        district: values.district,
      };

      setLoadingCreateTenant(true);
      try {
        await createTenant(body);
        showInfo("Tenant created successfully.");
        navigate("/tenants/list");
      } catch (error) {
        handleError(error);
        setLoadingCreateTenant(false);
      }
    },
  });

  return (
    <FormWrapper title="Tenan Create Form">
      <form className="grid" onSubmit={formik.handleSubmit}>
        <P002InputText
          label="Tenant Name"
          name="tenantName"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputText
          label="Tenant Code"
          name="tenantCode"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputText
          label="Tenant Address"
          name="address"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputText
          label="Tenant City"
          name="city"
          formik={formik}
          rootCol={6}
          rootLgCol={3}
          inputCol={12}
          required
        />
        <P002InputText
          label="Tenant District"
          name="district"
          formik={formik}
          rootCol={6}
          rootLgCol={3}
          inputCol={12}
          required
        />

        <div className="col-12">
          <Button
            type="submit"
            label={t("Save")}
            loading={loadingCreateTenant}
          />
        </div>
      </form>
    </FormWrapper>
    // <Form layout="vertical" onFinish={onFinish}>
    //   <Card size="small" title="Tenant Create Form">
    //     <Row gutter={[16, 16]}>
    //       <Col xs={24} lg={12}>
    //         <Form.Item
    //           name="tenantName"
    //           label="Tenant Name"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input tenant name!",
    //             },
    //             {
    //               max: 100,
    //               message: "Maximum 100 characters!",
    //             },
    //           ]}
    //         >
    //           <Input />
    //         </Form.Item>
    //       </Col>
    //       <Col xs={24} lg={12}>
    //         <Form.Item
    //           name="tenantCode"
    //           label="Tenant Code"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input tenant code!",
    //             },
    //             {
    //               max: 100,
    //               message: "Maximum 100 characters!",
    //             },
    //           ]}
    //         >
    //           <Input />
    //         </Form.Item>
    //       </Col>
    //       <Col xs={24} lg={12}>
    //         <Form.Item
    //           name="address"
    //           label="Tenant Address"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input tenant address!",
    //             },
    //           ]}
    //         >
    //           <Input.TextArea maxLength={250} />
    //         </Form.Item>
    //       </Col>
    //       <Col xs={12} lg={6}>
    //         <Form.Item
    //           name="city"
    //           label="Tenant City"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input tenant city!",
    //             },
    //             {
    //               max: 30,
    //               message: "Maximum 30 characters!",
    //             },
    //           ]}
    //         >
    //           <Input />
    //         </Form.Item>
    //       </Col>
    //       <Col xs={12} lg={6}>
    //         <Form.Item
    //           name="district"
    //           label="Tenant District"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input tenant district!",
    //             },
    //             {
    //               max: 30,
    //               message: "Maximum 30 characters!",
    //             },
    //           ]}
    //         >
    //           <Input />
    //         </Form.Item>
    //       </Col>
    //     </Row>

    //     <Form.Item style={{ marginBottom: 8 }}>
    //       <Button
    //         type="primary"
    //         htmlType="submit"
    //         loading={loadingCreateTenant}
    //       >
    //         Create Tenant
    //       </Button>
    //     </Form.Item>
    //   </Card>
    // </Form>
  );
};

export default TenantCreatePage;
