import useErrorHandler from "../../hooks/useErrorHandler";
import {
  getBillPrintSettings,
  updateBillPrintSettings,
} from "../../apis/billPrintSettingsApis";
import { useEffect, useState } from "react";
import UploadImage from "../../components/common/UploadImage";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import FormWrapper from "../../components/common/FormWrapper";
import { t } from "i18next";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import Bill from "../../components/order/Bill";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { validateInputText } from "../../common/FormValidationUtils";
import { useToast } from "../../components/common/ToastProvider";

const BillPrintSettingsPage = () => {
  const [billPrintSettings, setBillPrintSettings] = useState({});

  const [loadingLogoImage, setLoadingLogoImage] = useState(true);

  const [loadingGetBillPrintSettings, setLoadingGetBillPrintSettings] =
    useState(true);
  const [loadingUpdateBillPrintSettings, setLoadingUpdateBillPrintSettings] =
    useState(false);

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logoImage: null,
      businessName: billPrintSettings.businessName
        ? billPrintSettings.businessName
        : "",
      businessAddress: billPrintSettings.businessAddress
        ? billPrintSettings.businessAddress
        : "",
      businessCity: billPrintSettings.businessCity
        ? billPrintSettings.businessCity
        : "",
      businessDistrict: billPrintSettings.businessDistrict
        ? billPrintSettings.businessDistrict
        : "",
      footerMessage: billPrintSettings.footerMessage
        ? billPrintSettings.footerMessage
        : "",
      showLogo: billPrintSettings.showLogo ? billPrintSettings.showLogo : false,
      showBusinessName: billPrintSettings.showBusinessName
        ? billPrintSettings.showBusinessName
        : false,
      showBusinessAddress: billPrintSettings.showBusinessAddress
        ? billPrintSettings.showBusinessAddress
        : false,
      showNote: billPrintSettings.showNote ? billPrintSettings.showNote : false,
      showFooterMessage: billPrintSettings.showFooterMessage
        ? billPrintSettings.showFooterMessage
        : false,
    },

    validate: (data) => {
      let errors = {};

      if (data.showLogo && data.logoImage === null) {
        errors.logoImage = t("Must not be empty!");
      }
      if (data.showBusinessName) {
        validateInputText(
          "businessName",
          data.businessName,
          true,
          1,
          100,
          errors
        );
      }
      if (data.showBusinessAddress) {
        validateInputText(
          "businessAddress",
          data.businessAddress,
          true,
          1,
          250,
          errors
        );
        validateInputText(
          "businessCity",
          data.businessCity,
          true,
          1,
          30,
          errors
        );
        validateInputText(
          "businessDistrict",
          data.businessDistrict,
          true,
          1,
          30,
          errors
        );
      }
      if (data.showFooterMessage) {
        validateInputText(
          "footerMessage",
          data.footerMessage,
          true,
          1,
          100,
          errors
        );
      }
      return errors;
    },
    onSubmit: async (values) => {
      const body = {
        logoImage: values.logoImage
          ? values.logoImage.split("base64,")[1]
          : null,
        businessName: values.businessName ? values.businessName : null,
        businessAddress: values.businessAddress ? values.businessAddress : null,
        businessCity: values.businessCity ? values.businessCity : null,
        businessDistrict: values.businessDistrict
          ? values.businessDistrict
          : null,
        footerMessage: values.footerMessage ? values.footerMessage : null,
        showLogo: values.showLogo,
        showBusinessName: values.showBusinessName,
        showBusinessAddress: values.showBusinessAddress,
        showNote: values.showNote,
        showFooterMessage: values.showFooterMessage,
      };
      setLoadingUpdateBillPrintSettings(true);
      try {
        await updateBillPrintSettings(body);
        showInfo(t("Value updated.", { value: t("Bill Print Settings") }));
      } catch (error) {
        handleError(error);
      }
      setLoadingUpdateBillPrintSettings(false);
    },
  });

  useEffect(() => {
    const fetchBillPrintSettings = async () => {
      try {
        const response = await getBillPrintSettings();
        setBillPrintSettings(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetBillPrintSettings(false);
    };

    fetchBillPrintSettings();
  }, []);

  return (
    !loadingGetBillPrintSettings && (
      <div className="grid">
        <div className="col-12 lg:col-6">
          <FormWrapper
            title={t("Value Update Form", {
              value: t("Bill Print Settings"),
            })}
          >
            <form className="flex flex-column" onSubmit={formik.handleSubmit}>
              <div className="field flex flex-column">
                <label>{t("Logo Image")}</label>
                <UploadImage
                  formik={formik}
                  name="logoImage"
                  initialImageUrl={
                    billPrintSettings.logoImageFileName
                      ? `/images/tenants/${authorizationToken.tenant.code}/bill-print-settings/logo/${billPrintSettings.logoImageFileName}`
                      : undefined
                  }
                  loading={loadingLogoImage}
                  setLoading={setLoadingLogoImage}
                />
                {formik.errors.logoImage && (
                  <small className="p-error">{formik.errors.logoImage}</small>
                )}
              </div>
              <div className="field flex flex-column">
                <label>{t("Value Name", { value: t("Business") })}</label>
                <InputText
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  invalid={formik.errors.businessName}
                />
                {formik.errors.businessName && (
                  <small className="p-error">
                    {formik.errors.businessName}
                  </small>
                )}
              </div>
              <div className="field flex flex-column">
                <label>{t("Value Address", { value: t("Business") })}</label>
                <InputText
                  name="businessAddress"
                  value={formik.values.businessAddress}
                  onChange={formik.handleChange}
                  invalid={formik.errors.businessAddress}
                />
                {formik.errors.businessAddress && (
                  <small className="p-error">
                    {formik.errors.businessAddress}
                  </small>
                )}
              </div>
              <div className="field flex flex-row">
                <div className="flex flex-column lg:w-6 lg:pr-1">
                  <label>{t("Business City")}</label>
                  <InputText
                    name="businessCity"
                    value={formik.values.businessCity}
                    onChange={formik.handleChange}
                    invalid={formik.errors.businessCity}
                  />
                  {formik.errors.businessCity && (
                    <small className="p-error">
                      {formik.errors.businessCity}
                    </small>
                  )}
                </div>
                <div className="flex flex-column lg:w-6 lg:pl-1">
                  <label>{t("Business District")}</label>
                  <InputText
                    name="businessDistrict"
                    value={formik.values.businessDistrict}
                    onChange={formik.handleChange}
                    invalid={formik.errors.businessDistrict}
                  />
                  {formik.errors.businessDistrict && (
                    <small className="p-error">
                      {formik.errors.businessDistrict}
                    </small>
                  )}
                </div>
              </div>
              <div className="field flex flex-column">
                <label>{t("Footer Message")}</label>
                <InputText
                  name="footerMessage"
                  value={formik.values.footerMessage}
                  onChange={formik.handleChange}
                  invalid={formik.errors.footerMessage}
                />
                {formik.errors.footerMessage && (
                  <small className="p-error">
                    {formik.errors.footerMessage}
                  </small>
                )}
              </div>
              <div className="field flex align-items-center">
                <Checkbox
                  name="showLogo"
                  onChange={formik.handleChange}
                  checked={formik.values.showLogo}
                />
                <label className="ml-2 mb-0">{t("Show Logo")}</label>
              </div>
              <div className="field flex align-items-center">
                <Checkbox
                  name="showBusinessName"
                  onChange={formik.handleChange}
                  checked={formik.values.showBusinessName}
                />
                <label className="ml-2 mb-0">{t("Show Business Name")}</label>
              </div>
              <div className="field flex align-items-center">
                <Checkbox
                  name="showBusinessAddress"
                  onChange={formik.handleChange}
                  checked={formik.values.showBusinessAddress}
                />
                <label className="ml-2 mb-0">
                  {t("Show Business Address")}
                </label>
              </div>
              <div className="field flex align-items-center">
                <Checkbox
                  name="showNote"
                  onChange={formik.handleChange}
                  checked={formik.values.showNote}
                />
                <label className="ml-2 mb-0">{t("Show Note")}</label>
              </div>
              <div className="field flex align-items-center">
                <Checkbox
                  name="showFooterMessage"
                  onChange={formik.handleChange}
                  checked={formik.values.showFooterMessage}
                />
                <label className="ml-2 mb-0">{t("Show Footer Message")}</label>
              </div>
              <Button
                type="submit"
                label={t("Update")}
                loading={loadingUpdateBillPrintSettings || loadingLogoImage}
              />
            </form>
          </FormWrapper>
        </div>
        <div className="col-12 lg:col-6">
          <Bill
            printable
            previewMode
            billPrintSettings={formik.values}
            order={{
              createdDateTime: new Date(),
              note: "Test",
            }}
            orderProducts={[
              { id: 1, productName: "Test1", productPrice: 10, quantity: 1 },
              { id: 2, productName: "Test2", productPrice: 20.5, quantity: 2 },
            ]}
          />
        </div>
      </div>
    )
  );
};

export default BillPrintSettingsPage;
