import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListPayments } from "../../apis/paymentApis";
import { searchListOrderProducts } from "../../apis/orderProductApis";
import { searchListOrders } from "../../apis/orderApis";
import { formatTL, validateAndGetDateParam } from "../../common/CommonUtils";
import Bill from "../../components/order/Bill";
import FormWrapper from "../../components/common/FormWrapper";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { t } from "i18next";
import moment from "moment";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { BlockUI } from "primereact/blockui";

const StatisticsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [orders, setOrders] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [payments, setPayments] = useState([]);

  const [loadingSearchListOrders, setLoadingSearchListOrders] = useState(true);
  const [loadingSearchListOrderProducts, setLoadingSearchListOrderProducts] =
    useState(true);
  const [loadingSearchListPayments, setLoadingSearchListPayments] =
    useState(true);

  const handleError = useErrorHandler();

  const createdDateTimeMinParam = searchParams.get("createdDateTimeMin");
  const createdDateTimeMaxParam = searchParams.get("createdDateTimeMax");

  const defaultDateTimeMin = new Date();
  defaultDateTimeMin.setHours(0, 0, 0, 0);
  const createdDateTimeMin = validateAndGetDateParam(
    createdDateTimeMinParam,
    defaultDateTimeMin
  );

  const defaultDateTimeMax = new Date();
  defaultDateTimeMax.setHours(23, 59, 59, 59);
  const createdDateTimeMax = validateAndGetDateParam(
    createdDateTimeMaxParam,
    defaultDateTimeMax
  );

  const formik = useFormik({
    initialValues: {
      createdDateTimeMin,
      createdDateTimeMax,
    },

    onSubmit: (values) => {
      searchParams.set(
        "createdDateTimeMin",
        moment(values.createdDateTimeMin).valueOf()
      );
      searchParams.set(
        "createdDateTimeMax",
        moment(values.createdDateTimeMax).valueOf()
      );
      setSearchParams(searchParams);
    },
  });

  const fakeOrderProductMap = new Map();
  orderProducts.forEach((orderProduct) => {
    const key = orderProduct.productName + orderProduct.productPrice;
    fakeOrderProductMap.set(
      key,
      fakeOrderProductMap.get(key)
        ? {
            ...fakeOrderProductMap.get(key),
            quantity:
              fakeOrderProductMap.get(key).quantity + orderProduct.quantity,
          }
        : {
            id: key,
            productName: orderProduct.productName,
            productPrice: orderProduct.productPrice,
            quantity: orderProduct.quantity,
          }
    );
  });

  useEffect(() => {
    const fetchOrders = async () => {
      const orderSearchDTO = {
        createdDateTimeMin: moment(createdDateTimeMin).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        createdDateTimeMax: moment(createdDateTimeMax).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        statusList: ["CLOSE"],
      };
      setLoadingSearchListOrders(true);
      setLoadingSearchListOrderProducts(true);
      setLoadingSearchListPayments(true);
      try {
        const response = await searchListOrders(orderSearchDTO);
        setOrders(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListOrders(false);
    };

    fetchOrders();
  }, [createdDateTimeMinParam, createdDateTimeMaxParam]);

  useEffect(() => {
    if (!loadingSearchListOrders) {
      if (orders.length > 0) {
        const fetchOrderProducts = async () => {
          const orderProductSearchDTO = {
            orderIdList: orders.map((order) => order.id),
          };

          try {
            const response = await searchListOrderProducts(
              orderProductSearchDTO
            );
            setOrderProducts(response.data);
          } catch (error) {
            handleError(error);
          }
          setLoadingSearchListOrderProducts(false);
        };

        const fetchPayments = async () => {
          const paymentSearchDTO = {
            orderIdList: orders.map((order) => order.id),
          };

          try {
            const response = await searchListPayments(paymentSearchDTO);
            setPayments(response.data);
          } catch (error) {
            handleError(error);
          }
          setLoadingSearchListPayments(false);
        };

        fetchOrderProducts();
        fetchPayments();
      } else {
        setOrderProducts([]);
        setPayments([]);
        setLoadingSearchListOrderProducts(false);
        setLoadingSearchListPayments(false);
      }
    }
  }, [loadingSearchListOrders]);

  return (
    <div className="grid">
      <div className="col-12">
        <FormWrapper title={t("Value Filter Form", { value: t("Statistics") })}>
          <form className="grid" onSubmit={formik.handleSubmit}>
            <div className="col-12 lg:col-6 flex flex-column">
              <label>{t("Start Date")}</label>
              <Calendar
                name="createdDateTimeMin"
                value={formik.values.createdDateTimeMin}
                onChange={formik.handleChange}
                showTime
                hourFormat="24"
              />
            </div>
            <div className="col-12 lg:col-6 flex flex-column">
              <label>{t("End Date")}</label>
              <Calendar
                name="createdDateTimeMax"
                value={formik.values.createdDateTimeMax}
                onChange={formik.handleChange}
                showTime
                hourFormat="24"
              />
            </div>
            <div className="col-12">
              <Button
                type="submit"
                label={t("Filter")}
                loading={
                  loadingSearchListOrders ||
                  loadingSearchListOrderProducts ||
                  loadingSearchListPayments
                }
              />
            </div>
          </form>
        </FormWrapper>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <BlockUI
          blocked={loadingSearchListOrders}
          template={<i className="pi pi-spin pi-spinner" />}
        >
          <Card title={"" + orders.length} subTitle={t("Order Count")} />
        </BlockUI>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <BlockUI
          blocked={loadingSearchListOrders}
          template={<i className="pi pi-spin pi-spinner" />}
        >
          <Card
            subTitle={t("Total Customer Count")}
            title={
              "" + orders.reduce((sum, order) => sum + order.customerCount, 0)
            }
          />
        </BlockUI>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <BlockUI
          blocked={loadingSearchListOrderProducts}
          template={<i className="pi pi-spin pi-spinner" />}
        >
          <Card
            title={formatTL(
              orderProducts.reduce(
                (sum, orderProduct) =>
                  sum + orderProduct.quantity * orderProduct.productPrice * 100,
                0
              ) / 100
            )}
            subTitle={t("Total Turnover")}
          />
        </BlockUI>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <BlockUI
          blocked={loadingSearchListPayments}
          template={<i className="pi pi-spin pi-spinner" />}
        >
          <Card
            title={formatTL(
              payments.reduce((sum, payment) => sum + payment.amount * 100, 0) /
                100
            )}
            subTitle={t("Total Payments")}
          />
        </BlockUI>
      </div>
      <div className="col-12">
        <BlockUI
          blocked={
            loadingSearchListOrders ||
            loadingSearchListOrderProducts ||
            loadingSearchListPayments
          }
          template={<i className="pi pi-spin pi-spinner" />}
        >
          <Bill
            orderProducts={Array.from(fakeOrderProductMap.values()).sort(
              (a, b) => b.quantity - a.quantity
            )}
            payments={payments}
            readOnly
          />
        </BlockUI>
      </div>
    </div>
  );
};

export default StatisticsPage;
