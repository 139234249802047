import { useEffect, useState } from "react";
import {
  getQrMenuSettings,
  updateQrMenuSettings,
} from "../../apis/qrMenuSettingsApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import { QRCode } from "antd";
import UploadImage from "../../components/common/UploadImage";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import QrMenuPage from "../QrMenuPage";
import { useFormik } from "formik";
import { validateInputText } from "../../common/FormValidationUtils";
import { t } from "i18next";
import FormWrapper from "../../components/common/FormWrapper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useToast } from "../../components/common/ToastProvider";

const QrMenuSettingsPage = () => {
  const [qrMenuSettings, setQrMenuSettings] = useState({});

  const [loadingLogoImage, setLoadingLogoImage] = useState(true);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [loadingGetQrMenuSettings, setLoadingGetQrMenuSettings] =
    useState(true);
  const [loadingUpdateQrMenuSettings, setLoadingUpdateQrMenuSettings] =
    useState(false);

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logoImage: null,
      businessName: qrMenuSettings.businessName
        ? qrMenuSettings.businessName
        : "",
    },

    validate: (data) => {
      let errors = {};

      validateInputText(
        "businessName",
        data.businessName,
        false,
        0,
        100,
        errors
      );

      return errors;
    },
    onSubmit: async (values) => {
      const body = {
        logoImage: values.logoImage
          ? values.logoImage.split("base64,")[1]
          : null,
        businessName: values.businessName ? values.businessName : null,
      };
      setLoadingUpdateQrMenuSettings(true);
      try {
        await updateQrMenuSettings(body);
        showInfo(t("Value updated.", { value: t("Qr Menu Settings") }));
      } catch (error) {
        handleError(error);
      }
      setLoadingUpdateQrMenuSettings(false);
    },
  });

  useEffect(() => {
    const fetchQrMenuSettings = async () => {
      try {
        const response = await getQrMenuSettings();
        setQrMenuSettings(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetQrMenuSettings(false);
    };

    fetchQrMenuSettings();
  }, []);

  return (
    !loadingGetQrMenuSettings && (
      <div className="grid">
        <div className="col-12 lg:col-6">
          <FormWrapper
            title={t("Value Update Form", {
              value: t("Qr Menu Settings"),
            })}
          >
            <form className="flex flex-column" onSubmit={formik.handleSubmit}>
              <div className="field flex flex-column">
                <label>{t("Logo Image")}</label>
                <UploadImage
                  formik={formik}
                  name="logoImage"
                  initialImageUrl={
                    qrMenuSettings.logoImageFileName
                      ? `/images/tenants/${authorizationToken.tenant.code}/qr-menu/logo/${qrMenuSettings.logoImageFileName}`
                      : undefined
                  }
                  loading={loadingLogoImage}
                  setLoading={setLoadingLogoImage}
                />
                {/* {formik.errors.logoImage && (
                  <small className="p-error">{formik.errors.logoImage}</small>
                )} */}
              </div>
              <div className="field flex flex-column">
                <label>{t("Value Name", { value: t("Business") })}</label>
                <InputText
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  invalid={formik.errors.businessName}
                />
                {formik.errors.businessName && (
                  <small className="p-error">
                    {formik.errors.businessName}
                  </small>
                )}
              </div>
              <Button
                type="submit"
                label={t("Update")}
                loading={loadingUpdateQrMenuSettings || loadingLogoImage}
              />
            </form>
          </FormWrapper>
        </div>
        <div className="col-12 lg:col-6">
          <Button
            className="mb-2"
            type="button"
            label={t("See Menu")}
            icon="pi pi-eye"
            onClick={() => {
              setPreviewOpen(true);
            }}
          />
          <QRCode
            value={`${process.env.REACT_APP_DOMAIN}/${authorizationToken.tenant.code}/qr-menu`}
          />
          <Dialog
            visible={previewOpen}
            draggable={false}
            style={{ width: "50vw" }}
            onHide={() => {
              setPreviewOpen(false);
            }}
          >
            <QrMenuPage
              previewMode
              tenantCode={authorizationToken.tenant.code}
              qrMenuSettings={qrMenuSettings}
            />
          </Dialog>
        </div>
      </div>
    )
  );
};

export default QrMenuSettingsPage;
