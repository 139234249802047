import React, { useEffect, useState } from "react";
import FormWrapper from "../common/FormWrapper";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useToast } from "../common/ToastProvider";
import P002Dropdown from "../input/P002Dropdown";
import {
  createCategory,
  searchListCategories,
  updateCategory,
} from "../../apis/categoryApis";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import P002Checkbox from "../input/P002Checkbox";
import { t } from "i18next";
import {
  validateInputNumber,
  validateInputText,
} from "../../common/FormValidationUtils";
import P002InputText from "../input/P002InputText";
import P002InputNumber from "../input/P002InputNumber";

const CategoryForm = (props) => {
  const { action, category, setCategory } = props;

  const [categories, setCategories] = useState([]);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      parentCategoryId: category ? category.parentCategoryId : undefined,
      categoryName: category ? category.name : "",
      position: category ? category.position : 0,
      showInQrMenu: category ? category.showInQrMenu : true,
    },

    validate: (values) => {
      let errors = {};

      validateInputText(
        "categoryName",
        values.categoryName,
        true,
        2,
        20,
        errors
      );
      validateInputNumber("position", values.position, true, 0, null, errors);

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        parentCategoryId: values.parentCategoryId
          ? values.parentCategoryId
          : null,
        name: values.categoryName,
        position: values.position,
        showInQrMenu: values.showInQrMenu,
      };

      setLoadingSubmit(true);
      try {
        if (action === "CREATE") {
          const response = await createCategory(body);
          showInfo(t("Value created.", { value: t("Category") }));
          formik.resetForm();
          setCategories((prevState) => [...prevState, response.data]);
        } else if (action === "UPDATE") {
          const response = await updateCategory(category.id, body);
          setCategory(response.data);
          showInfo(t("Value updated.", { value: t("Category") }));
        }
      } catch (error) {
        handleError(error);
      }
      setLoadingSubmit(false);
    },
  });

  return (
    <FormWrapper
      title={
        action === "CREATE"
          ? t("Value Create Form", { value: t("Category") })
          : action === "UPDATE"
          ? t("Value Update Form", { value: t("Category") })
          : ""
      }
    >
      <form className="grid" onSubmit={formik.handleSubmit}>
        <P002Dropdown
          label={t("Value Name", { value: t("Parent Category") })}
          name="parentCategoryId"
          formik={formik}
          rootCol={12}
          inputCol={12}
          inputLgCol={6}
          options={categories
            .filter(
              (item) =>
                item.parentCategoryId === null &&
                (action === "UPDATE"
                  ? item.id !== Number(category.id)
                  : 1 === 1)
            )
            .map((category) => ({
              label: category.name,
              value: category.id,
            }))}
          loading={loadingSearchListCategories}
          showClear
        />

        <P002InputText
          label={t("Value Name", { value: t("Category") })}
          name="categoryName"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputNumber
          label={t("Value Position", { value: t("Category") })}
          name="position"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002Checkbox
          label={t("Show in QR menu")}
          name="showInQrMenu"
          formik={formik}
          rootCol={12}
          inputCol={12}
        />

        <div className="col-12">
          <Button
            type="submit"
            label={t("Save")}
            loading={loadingSubmit || loadingSearchListCategories}
          />
        </div>
      </form>
    </FormWrapper>
  );
};

export default CategoryForm;
