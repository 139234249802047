import { Button } from "primereact/button";
import UserForm from "../../components/forms/UserForm";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const UserCreatePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        className="p-1"
        label={`< ${t("List Value", { value: t("User") })}`}
        link
        onClick={() => {
          navigate("/users/list");
        }}
      />
      <UserForm />
    </>
  );
};

export default UserCreatePage;
