import { InputNumber } from "primereact/inputnumber";

const P002InputNumber = (props) => {
  const {
    label,
    name,
    formik,
    rootCol,
    rootLgCol,
    inputCol,
    inputLgCol,
    required,
    maxFractionDigits,
    inputGroup,
    inputGroupItem,
  } = props;

  return (
    <div
      className={`${rootCol ? `col-${rootCol} p-0` : ""} ${
        rootLgCol ? `lg:col-${rootLgCol}` : ""
      } `}
    >
      <div
        className={`${inputCol ? "col-" + inputCol : ""} 
        ${inputLgCol ? `lg:col-${inputLgCol}` : ""} 
        flex flex-column`}
      >
        <label className="pb-1">
          {required && <span className="text-red-600">* </span>}
          {label}
        </label>
        {inputGroup ? (
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">{inputGroupItem}</span>
            <InputNumber
              name={name}
              value={formik.values[name]}
              onChange={(e) => {
                formik.setFieldValue(name, e.value);
              }}
              invalid={formik.errors[name]}
              useGrouping={false}
              maxFractionDigits={maxFractionDigits ? maxFractionDigits : null}
            />
          </div>
        ) : (
          <InputNumber
            name={name}
            value={formik.values[name]}
            onChange={(e) => {
              formik.setFieldValue(name, e.value);
            }}
            invalid={formik.errors[name]}
            useGrouping={false}
            maxFractionDigits={maxFractionDigits ? maxFractionDigits : null}
          />
        )}
        {formik.errors[name] && (
          <small className="p-error">{formik.errors[name]}</small>
        )}
      </div>
    </div>
  );
};

export default P002InputNumber;
