import { useEffect, useState } from "react";
import {
  createBalanceAccount,
  searchListBalanceAccounts,
} from "../../apis/balanceAccountApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import { t } from "i18next";
import {
  createBalanceAccountTransaction,
  getBalanceAccountTransactionListByBalanceAccountId,
} from "../../apis/balanceAccountTransactionApis";
import { formatTL } from "../../common/CommonUtils";
import { Card } from "primereact/card";
import SolidDivider from "../../components/common/SolidDivider";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import {
  validateInputNumber,
  validateInputText,
} from "../../common/FormValidationUtils";
import P002AutoComplete from "../../components/input/P002AutoComplete";
import { Dialog } from "primereact/dialog";
import FormWrapper from "../../components/common/FormWrapper";
import P002InputText from "../../components/input/P002InputText";
import { BlockUI } from "primereact/blockui";
import P002InputNumber from "../../components/input/P002InputNumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

const BalanceManagementPage = () => {
  const [balanceAccountList, setBalanceAccountList] = useState([]);

  const [balanceAccountTransactionList, setBalanceAccountTransactionList] =
    useState([]);

  const [
    loadingSearchListBalanceAccounts,
    setLoadingSearchListBalanceAccounts,
  ] = useState(true);
  const [
    loadingGetBalanceAccountTransactionListByBalanceAccountId,
    setLoadingGetBalanceAccountTransactionListByBalanceAccountId,
  ] = useState(false);
  const [loadingCreateBalanceAccount, setLoadingCreateBalanceAccount] =
    useState(false);
  const [
    loadingCreateBalanceAccountTransaction,
    setLoadingCreateBalanceAccountTransaction,
  ] = useState(false);

  const [createTransactionDialogOpen, setCreateTransactionDialogOpen] =
    useState(false);
  const [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);

  const handleError = useErrorHandler();

  const selectAccountFormik = useFormik({
    initialValues: {
      balanceAccountId: undefined,
    },

    validate: (values) => {
      let errors = {};

      return errors;
    },

    onSubmit: async (values) => {},
  });

  const createAccountFormik = useFormik({
    initialValues: {
      balanceAccountName: "",
    },

    validate: (values) => {
      let errors = {};

      validateInputText(
        "balanceAccountName",
        values.balanceAccountName,
        true,
        2,
        50,
        errors
      );

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        name: values.balanceAccountName,
        type: "CUSTOMER",
      };
      setLoadingCreateBalanceAccount(true);
      try {
        const response = await createBalanceAccount(body);
        setBalanceAccountList([response.data, ...balanceAccountList]);
        createAccountFormik.resetForm();
        selectAccountFormik.setFieldValue("balanceAccountId", response.data.id);
      } catch (error) {
        handleError(error);
      }
      setLoadingCreateBalanceAccount(false);
      setCreateAccountDialogOpen(false);
    },
  });

  const createTransactionFormik = useFormik({
    initialValues: {
      amount: undefined,
      description: "",
    },

    validate: (values) => {
      let errors = {};

      validateInputNumber(
        "amount",
        values.amount,
        true,
        -999999.99,
        999999.99,
        errors
      );
      validateInputText(
        "description",
        values.description,
        false,
        1,
        200,
        errors
      );

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        amount: values.amount,
        description: values.description.length > 0 ? values.description : null,
      };
      setLoadingCreateBalanceAccountTransaction(true);
      try {
        const response = await createBalanceAccountTransaction(
          selectAccountFormik.values.balanceAccountId,
          body
        );
        setBalanceAccountTransactionList([
          response.data,
          ...balanceAccountTransactionList,
        ]);
        createTransactionFormik.resetForm();
      } catch (error) {
        handleError(error);
      }
      setLoadingCreateBalanceAccountTransaction(false);
      setCreateTransactionDialogOpen(false);
    },
  });

  const balanceAccountMap = new Map();
  balanceAccountList.forEach((balanceAccount) => {
    balanceAccountMap.set(balanceAccount.id, balanceAccount);
  });

  useEffect(() => {
    const fetchBalanceAccounts = async () => {
      try {
        const response = await searchListBalanceAccounts();
        setBalanceAccountList(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListBalanceAccounts(false);
    };

    fetchBalanceAccounts();
  }, []);

  useEffect(() => {
    const fetchBalanceAccountTransactions = async () => {
      setLoadingGetBalanceAccountTransactionListByBalanceAccountId(true);
      try {
        const response =
          await getBalanceAccountTransactionListByBalanceAccountId(
            selectAccountFormik.values.balanceAccountId
          );
        setBalanceAccountTransactionList(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetBalanceAccountTransactionListByBalanceAccountId(false);
    };

    if (selectAccountFormik.values.balanceAccountId) {
      fetchBalanceAccountTransactions();
    } else {
      setBalanceAccountTransactionList([]);
    }
  }, [selectAccountFormik.values.balanceAccountId]);

  return (
    !loadingSearchListBalanceAccounts && (
      <div className="grid">
        <div className="col-12">
          <Card
            pt={{
              content: "p-2",
              body: "p-0",
              title: "mb-0 py-1 text-base font-semibold",
            }}
            title={
              <>
                <div className="flex flex-row p-2 align-items-center">
                  <div className="flex-grow-1">{t("Balance Account")}</div>
                  <Button
                    size="small"
                    icon="pi pi-plus"
                    label={t("Add Balance Account")}
                    onClick={() => {
                      setCreateAccountDialogOpen(true);
                    }}
                    disabled={selectAccountFormik.values.balanceAccountId}
                  />
                  <Dialog
                    visible={createAccountDialogOpen}
                    style={{ width: "50vw" }}
                    draggable={false}
                    onHide={() => {
                      setCreateAccountDialogOpen(false);
                    }}
                  >
                    <FormWrapper
                      title={t("Value Create Form", {
                        value: t("Balance Account"),
                      })}
                    >
                      <form
                        className="grid"
                        onSubmit={createAccountFormik.handleSubmit}
                      >
                        <P002InputText
                          formik={createAccountFormik}
                          name="balanceAccountName"
                          label={t("Name")}
                          rootCol={12}
                          inputCol={12}
                          required
                        />
                        <div className="col-12">
                          <Button
                            type="submit"
                            label={t("Save")}
                            loading={loadingCreateBalanceAccount}
                          />
                        </div>
                      </form>
                    </FormWrapper>
                  </Dialog>
                </div>

                <div className="pt-1">
                  <SolidDivider />
                </div>
              </>
            }
          >
            <form className="grid" onSubmit={selectAccountFormik.handleSubmit}>
              <P002AutoComplete
                formik={selectAccountFormik}
                name="balanceAccountId"
                label={t("Value Name", { value: t("Account") })}
                options={balanceAccountList}
                disabled={selectAccountFormik.values.balanceAccountId}
                rootCol={12}
                inputCol={12}
              />
              {selectAccountFormik.values.balanceAccountId && (
                <div className="col-12">
                  <Button
                    type="button"
                    label={t("Reset")}
                    onClick={() => {
                      selectAccountFormik.setFieldValue(
                        "balanceAccountId",
                        undefined
                      );
                    }}
                  />
                </div>
              )}
            </form>
          </Card>
        </div>
        {selectAccountFormik.values.balanceAccountId && (
          <>
            <div className="col-12 md:col-6 lg:col-3">
              <BlockUI
                blocked={
                  loadingGetBalanceAccountTransactionListByBalanceAccountId
                }
                template={<i className="pi pi-spin pi-spinner" />}
              >
                <Card
                  title={formatTL(
                    balanceAccountTransactionList.reduce(
                      (sum, transaction) => sum + transaction.amount,
                      0
                    )
                  )}
                  subTitle={t("Total")}
                />
              </BlockUI>
            </div>

            <div className="col-12">
              <Button
                size="small"
                icon="pi pi-plus"
                label={t("Add Transaction")}
                onClick={() => {
                  setCreateTransactionDialogOpen(true);
                }}
              />
              <Dialog
                visible={createTransactionDialogOpen}
                style={{ width: "50vw" }}
                draggable={false}
                onHide={() => {
                  setCreateTransactionDialogOpen(false);
                }}
              >
                <FormWrapper
                  title={t("Value Create Form", { value: t("Transaction") })}
                >
                  <form
                    className="grid"
                    onSubmit={createTransactionFormik.handleSubmit}
                  >
                    <P002InputNumber
                      formik={createTransactionFormik}
                      name="amount"
                      label={t("Amount")}
                      maxFractionDigits={2}
                      rootCol={12}
                      inputCol={12}
                      required
                    />
                    <P002InputText
                      formik={createTransactionFormik}
                      name="description"
                      label={t("Description")}
                      rootCol={12}
                      inputCol={12}
                    />
                    <div className="col-12">
                      <Button
                        type="submit"
                        label={t("Save")}
                        loading={loadingCreateBalanceAccountTransaction}
                      />
                    </div>
                  </form>
                </FormWrapper>
              </Dialog>
            </div>
            <div className="col-12">
              <DataTable
                showGridlines
                value={balanceAccountTransactionList}
                dataKey="id"
                loading={
                  loadingGetBalanceAccountTransactionListByBalanceAccountId
                }
              >
                <Column
                  className="w-full"
                  field="description"
                  header={t("Description")}
                />
                <Column field="amount" header={t("Amount")} />
                <Column
                  field="createdDate"
                  header={t("Created Date")}
                  body={(transaction) =>
                    moment(transaction.createdDate).format("DD.MM.YYYY HH:mm")
                  }
                />
              </DataTable>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default BalanceManagementPage;
