import { useFormik } from "formik";
import FormWrapper from "../common/FormWrapper";
import { useState } from "react";
import { createUser } from "../../apis/userApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useToast } from "../common/ToastProvider";
import P002InputText from "../input/P002InputText";
import P002Password from "../input/P002Password";
import { TENANT_ADMIN, TENANT_WAITER } from "../../common/Constants";
import { Button } from "primereact/button";
import { t } from "i18next";
import P002Dropdown from "../input/P002Dropdown";
import P002InputNumber from "../input/P002InputNumber";
import {
  validateEmail,
  validateInputText,
  validateMobileNumber,
} from "../../common/FormValidationUtils";

const UserForm = (props) => {
  const { tenantId } = props;

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      contactEmail: "",
      contactNumber: undefined,
      role: "TENANT_ADMIN",
    },

    validate: (values) => {
      let errors = {};

      validateInputText("username", values.username, true, 4, 20, errors);
      validateInputText("password", values.password, true, 6, 20, errors);
      validateInputText("firstName", values.firstName, true, 2, 40, errors);
      validateInputText("lastName", values.firstName, true, 2, 40, errors);
      validateEmail("contactEmail", values.contactEmail, true, errors);
      validateMobileNumber(
        "contactNumber",
        "+90" + values.contactNumber,
        true,
        errors
      );

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        username: values.username,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        contactEmail: values.contactEmail,
        contactNumber: "+90" + values.contactNumber,
        role: values.role,
      };

      setLoadingSubmit(true);
      try {
        await createUser(body, tenantId);
        showInfo(t("Value created.", { value: t("User") }));
        formik.resetForm();
      } catch (error) {
        handleError(error);
      }
      setLoadingSubmit(false);
    },
  });

  return (
    <FormWrapper title={t("Value Create Form", { value: t("User") })}>
      <form className="grid" onSubmit={formik.handleSubmit}>
        <P002InputText
          label={t("Value Name", { value: t("User") })}
          name="username"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002Password
          label={t("Password")}
          name="password"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002Dropdown
          label={t("Role")}
          name="role"
          formik={formik}
          rootCol={12}
          inputCol={12}
          inputLgCol={6}
          required
          options={[
            { label: t("Admin"), value: TENANT_ADMIN },
            { label: t("Waiter"), value: TENANT_WAITER },
          ]}
        />
        <P002InputText
          label={t("First Name")}
          name="firstName"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputText
          label={t("Last Name")}
          name="lastName"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputText
          label={t("Contact Email")}
          name="contactEmail"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
        />
        <P002InputNumber
          label={t("Contact Number")}
          name="contactNumber"
          formik={formik}
          rootCol={12}
          rootLgCol={6}
          inputCol={12}
          required
          inputGroup
          inputGroupItem="+90"
        />

        <div className="col-12">
          <Button type="submit" label={t("Save")} loading={loadingSubmit} />
        </div>
      </form>
    </FormWrapper>
  );
};

export default UserForm;
