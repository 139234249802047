import { useSetRecoilState } from "recoil";
import { authorizationTokenState } from "../recoil/atoms";
import { t } from "i18next";
import { useToast } from "../components/common/ToastProvider";

const useErrorHandler = () => {
  const setAuthorizationToken = useSetRecoilState(authorizationTokenState);

  const { showError } = useToast();

  return (error) => {
    let errorMessage = t(
      "Something went wrong. Please contact with developer."
    );

    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        setAuthorizationToken(null);
      }

      if (error.response.data) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      }
    }

    showError(errorMessage);
  };
};

export default useErrorHandler;
