import useErrorHandler from "../../hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchTenants } from "../../apis/tenantApis";
import { validateAndGetTextParam } from "../../common/CommonUtils";
import { t } from "i18next";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";

const TenantListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tenantPage, setTenantPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });

  const [loadingSearchTenants, setLoadingSearchTenants] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const pageParam = searchParams.get("page");
  const nameParam = searchParams.get("name");
  const name = validateAndGetTextParam(nameParam, 50);

  useEffect(() => {
    const fetchTenants = async () => {
      const tenantSearchDTO = {
        name,
      };
      setLoadingSearchTenants(true);
      try {
        const response = await searchTenants(
          pageParam - 1,
          20,
          tenantSearchDTO
        );
        setTenantPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchTenants(false);
    };

    fetchTenants();
  }, [pageParam, nameParam]);

  const onPage = (event) => {
    searchParams.set("page", event.page + 1);
    setSearchParams(searchParams);
  };

  const onFilter = (event) => {
    if (event.filters.name.value) {
      searchParams.set("name", event.filters.name.value);
    } else {
      searchParams.delete("name");
    }

    setSearchParams(searchParams);
  };

  return (
    <DataTable
      size="small"
      showGridlines
      value={tenantPage.content}
      lazy
      dataKey="id"
      paginator
      first={pageParam ? (pageParam - 1) * 20 : 0}
      rows={20}
      totalRecords={tenantPage.totalElements}
      onPage={onPage}
      onFilter={onFilter}
      filters={{
        name: {
          value: name,
          matchMode: FilterMatchMode.CONTAINS,
        },
      }}
      loading={loadingSearchTenants}
    >
      <Column
        // className="w-full"
        pt={{ filterInput: { style: { minWidth: "100px" } } }}
        field="name"
        header={t("Name")}
        filter
        filterPlaceholder="Search"
        showFilterMatchModes={false}
      />
      <Column field="address" header={t("Address")} />
      <Column
        header={t("City/District")}
        body={(tenant) => `${tenant.city}/${tenant.district}`}
      />
      <Column
        header={t("Actions")}
        body={(tenant) => (
          <div className="flex flex-row">
            <Button
              size="small"
              className="p-1 mr-1"
              link
              label={t("Users")}
              onClick={() => {
                navigate(`/tenants/${tenant.id}/users/list`);
              }}
            />
          </div>
        )}
      />
    </DataTable>
    // <Row gutter={[16, 16]}>
    //   <Col xs={24}>
    //     <Card size="small" title="Tenant Search Filter Form">
    //       <Form layout="vertical" onFinish={onFinish} form={form}>
    //         <Row>
    //           <Col xs={24} lg={12}>
    //             <Form.Item
    //               name="tenantName"
    //               label="Name"
    //               initialValue={name}
    //               rules={[
    //                 {
    //                   max: 50,
    //                   message: "Maximum 50 characters!",
    //                 },
    //               ]}
    //             >
    //               <Input />
    //             </Form.Item>
    //           </Col>
    //           <Col xs={24}>
    //             <Form.Item style={{ marginBottom: 8 }}>
    //               <Row gutter={16}>
    //                 <Col style={{ flexGrow: 1 }}></Col>
    //                 <Col>
    //                   <Button
    //                     disabled={loadingSearchTenants}
    //                     onClick={() => {
    //                       form.setFieldValue("tenantName", undefined);
    //                     }}
    //                   >
    //                     Reset
    //                   </Button>
    //                 </Col>
    //                 <Col>
    //                   <Button
    //                     type="primary"
    //                     htmlType="submit"
    //                     loading={loadingSearchTenants}
    //                   >
    //                     Filter
    //                   </Button>
    //                 </Col>
    //               </Row>
    //             </Form.Item>
    //           </Col>
    //         </Row>
    //       </Form>
    //     </Card>
    //   </Col>
    //   <Col xs={24}>
    //     <Table
    //       scroll={{ x: "100%" }}
    //       bordered
    //       columns={columns}
    //       dataSource={tenantPage.content}
    //       pagination={{
    //         showSizeChanger: true,
    //         current: tenantPage.number + 1,
    //         pageSize: tenantPage.size,
    //         total: tenantPage.totalElements,
    //       }}
    //       rowKey="id"
    //       onChange={(pagination, filters, sorter) => {
    //         searchParams.set("page", pagination.current);
    //         searchParams.set("size", pagination.pageSize);
    //         setSearchParams(searchParams);
    //       }}
    //       loading={loadingSearchTenants}
    //     />
    //   </Col>
    // </Row>
  );
};

export default TenantListPage;
