import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  getTrendyolYemekIntegrationSettings,
  saveTrendyolYemekIntegrationSettings,
} from "../../apis/trendyolYemekIntegrationSettingsApi";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useToast } from "../../components/common/ToastProvider";
import FormWrapper from "../../components/common/FormWrapper";
import { useFormik } from "formik";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import {
  validateInputNumber,
  validateInputText,
} from "../../common/FormValidationUtils";
import { InputNumber } from "primereact/inputnumber";
import P002Password from "../../components/input/P002Password";
import P002InputNumber from "../../components/input/P002InputNumber";

const TrendyolYemekIntegrationPage = () => {
  const [
    loadingGetTrendyolYemekIntegrationSettings,
    setLoadingGetTrendyolYemekIntegrationSettings,
  ] = useState(true);
  const [
    loadingSaveTrendyolYemekIntegrationSettings,
    setLoadingSaveTrendyolYemekIntegrationSettings,
  ] = useState(false);

  const [
    trendyolYemekIntegrationSettings,
    setTrendyolYemekIntegrationSettings,
  ] = useState({ sellerId: undefined, apiKey: "", apiSecret: "" });

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  useEffect(() => {
    const fetchTrendyolYemekIntegrationSettings = async () => {
      try {
        const response = await getTrendyolYemekIntegrationSettings();
        setTrendyolYemekIntegrationSettings(response.data);
      } catch (error) {
        if (error.response.status !== 404) {
          handleError(error);
        }
      }
      setLoadingGetTrendyolYemekIntegrationSettings(false);
    };
    fetchTrendyolYemekIntegrationSettings();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sellerId: trendyolYemekIntegrationSettings.sellerId,
      apiKey: trendyolYemekIntegrationSettings.apiKey,
      apiSecret: trendyolYemekIntegrationSettings.apiSecret,
    },

    validate: (values) => {
      let errors = {};

      validateInputNumber(
        "sellerId",
        values.sellerId,
        true,
        1,
        9999999,
        errors
      );
      validateInputText("apiKey", values.apiKey, true, null, null, errors);
      validateInputText(
        "apiSecret",
        values.apiSecret,
        true,
        null,
        null,
        errors
      );

      return errors;
    },

    onSubmit: async (values) => {
      setLoadingSaveTrendyolYemekIntegrationSettings(true);
      const body = {
        sellerId: values.sellerId,
        apiKey: values.apiKey,
        apiSecret: values.apiSecret,
      };
      try {
        await saveTrendyolYemekIntegrationSettings(body);
        showInfo(
          t("Value saved.", { value: t("Trendyol Yemek Integration Settings") })
        );
      } catch (error) {
        handleError(error);
      }
      setLoadingSaveTrendyolYemekIntegrationSettings(false);
    },
  });

  return (
    !loadingGetTrendyolYemekIntegrationSettings && (
      <FormWrapper
        title={t("Value Update Form", {
          value: t("Trendyol Yemek Integration Settings"),
        })}
      >
        <form className="grid" onSubmit={formik.handleSubmit}>
          <P002InputNumber
            label="Satıcı ID"
            name="sellerId"
            formik={formik}
            rootCol={12}
            inputCol={12}
            inputLgCol={6}
            required
          />
          <P002Password
            label="API Key"
            name="apiKey"
            formik={formik}
            rootCol={12}
            rootLgCol={6}
            inputCol={12}
            required
          />
          <P002Password
            label="API Secret"
            name="apiSecret"
            formik={formik}
            rootCol={12}
            rootLgCol={6}
            inputCol={12}
            required
          />
          <div className="col-12">
            <Button
              type="submit"
              label={t("Save")}
              loading={
                loadingSaveTrendyolYemekIntegrationSettings ||
                loadingGetTrendyolYemekIntegrationSettings
              }
            />
          </div>
        </form>
      </FormWrapper>
    )
  );
};

export default TrendyolYemekIntegrationPage;
