import { Card } from "primereact/card";
import SolidDivider from "./SolidDivider";

const FormWrapper = (props) => {
  const { title } = props;

  return (
    <Card
      pt={{ content: "p-2", body: "p-0", title: "mb-0 text-base font-semibold" }}
      title={
        <>
          <div className="p-2">{title}</div>
          <SolidDivider />
        </>
      }
    >
      {props.children}
    </Card>
  );
};

export default FormWrapper;
