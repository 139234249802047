import axios from "axios";

export const createOrder = (body) => {
  return axios.post("/api/1.0/orders", body);
};

export const getOrder = (orderId) => {
  return axios.get(`/api/1.0/orders/${orderId}`);
};

export const searchPageOrders = (page, size, orderSearchDTO) => {
  const { statusList, tableId, createdDateTimeMin, createdDateTimeMax } =
    orderSearchDTO;

  const params = new URLSearchParams();
  params.append("page", page);
  params.append("size", size);
  if (statusList) {
    params.append("statusList", statusList);
  }
  if (tableId) {
    params.append("tableId", tableId);
  }
  if (createdDateTimeMin) {
    params.append("createdDateTimeMin", createdDateTimeMin);
  }
  if (createdDateTimeMax) {
    params.append("createdDateTimeMax", createdDateTimeMax);
  }

  return axios.get(`/api/1.0/orders/page?${params.toString()}`);
};

export const searchListOrders = (orderSearchDTO) => {
  const { statusList, tableId, createdDateTimeMin, createdDateTimeMax } =
    orderSearchDTO;

  const params = new URLSearchParams();
  if (statusList) {
    params.append("statusList", statusList);
  }
  if (tableId) {
    params.append("tableId", tableId);
  }
  if (createdDateTimeMin) {
    params.append("createdDateTimeMin", createdDateTimeMin);
  }
  if (createdDateTimeMax) {
    params.append("createdDateTimeMax", createdDateTimeMax);
  }

  return axios.get(`/api/1.0/orders/list?${params.toString()}`);
};

export const updateOrder = (orderId, body) => {
  return axios.put(`/api/1.0/orders/${orderId}`, body);
};

export const closeOrder = (orderId) => {
  return axios.put(`/api/1.0/orders/${orderId}/close`);
};
