import { Dropdown } from "primereact/dropdown";

const P002Dropdown = (props) => {
  const {
    label,
    name,
    formik,
    rootCol,
    rootLgCol,
    inputCol,
    inputLgCol,
    required,
    options,
    loading,
    showClear,
  } = props;

  return (
    <div
      className={`${rootCol ? `col-${rootCol} p-0` : ""} ${
        rootLgCol ? `lg:col-${rootLgCol}` : ""
      } `}
    >
      <div
        className={`${inputCol ? "col-" + inputCol : ""} 
        ${inputLgCol ? `lg:col-${inputLgCol}` : ""} 
        flex flex-column`}
      >
        <label className="pb-1">
          {required && <span className="text-red-600">* </span>}
          {label}
        </label>
        <Dropdown
          name={name}
          value={formik.values[name]}
          onChange={formik.handleChange}
          invalid={formik.errors[name]}
          optionLabel="label"
          optionValue="value"
          options={options}
          loading={loading}
          showClear={showClear}
        />
        {formik.errors[name] && (
          <small className="p-error">{formik.errors[name]}</small>
        )}
      </div>
    </div>
  );
};

export default P002Dropdown;
