import { Checkbox } from "primereact/checkbox";

const P002Checkbox = (props) => {
  const {
    label,
    name,
    formik,
    rootCol,
    rootLgCol,
    inputCol,
    inputLgCol,
    required,
  } = props;

  return (
    <div
      className={`${rootCol ? `col-${rootCol} p-0` : ""} ${
        rootLgCol ? `lg:col-${rootLgCol}` : ""
      } `}
    >
      <div
        className={`${inputCol ? "col-" + inputCol : ""} 
        ${inputLgCol ? `lg:col-${inputLgCol}` : ""} 
        flex flex-row`}
      >
        <Checkbox
          name={name}
          onChange={formik.handleChange}
          checked={formik.values[name]}
        />
        <label className="ml-2">
          {required && <span className="text-red-600">* </span>}
          {label}
        </label>
      </div>
    </div>
  );
};

export default P002Checkbox;
