import { UserAddOutlined } from "@ant-design/icons";
import UserList from "../../components/user/UserList";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { getTenant } from "../../apis/tenantApis";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

const UserListPage = () => {
  const { tenantId } = useParams();

  const [tenant, setTenant] = useState({});

  const [loadingGetTenant, setLoadingGetTenant] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const response = await getTenant(tenantId);
        setTenant(response.data);
        setLoadingGetTenant(false);
      } catch (error) {
        handleError(error);
        navigate("/tenants/list");
      }
    };

    fetchTenant();
  }, []);

  return (
    !loadingGetTenant && (
      <div className="grid">
        <div className="col-12">
          <Panel header="Tenant Info">
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-4">
                <b>Tenant Name: </b>
                {tenant.name}
              </div>
              <div className="col-12 md:col-6 lg:col-4">
                <b>Tenant Address: </b>
                {tenant.address}
              </div>
              <div className="col-12 md:col-6 lg:col-4">
                <b> Tenant City/District: </b>
                {tenant.city}/{tenant.district}
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-12">
          <Button
            size="small"
            type="button"
            label="Create User"
            icon="pi pi-user"
            loading={loadingGetTenant}
            onClick={() => {
              navigate(`/tenants/${tenantId}/users/create`);
            }}
          />
        </div>
        <div className="col-12">
          <UserList tenantId={tenantId} />
        </div>
      </div>
    )
  );
};

export default UserListPage;
