import MenuTabContent from "./MenuTabContent";
import PaymentsTabContent from "./PaymentsTabContent";
import OrderTabContent from "./OrderTabContent";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import Bill from "./Bill";
import { TENANT_ADMIN } from "../../common/Constants";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
import { closeOrder } from "../../apis/orderApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { useToast } from "../common/ToastProvider";

const OrderContent = (props) => {
  const {
    emptyTables,
    categories,
    products,
    order,
    orderProducts,
    payments,
    setOrders,
    setOrderProducts,
    setPayments,
    billPrintSettings,
  } = props;

  const location = useLocation();

  const readOnly =
    location.pathname !== "/order-management" || order.status === "CLOSE";

  const [loadingCloseOrder, setLoadingCloseOrder] = useState(false);

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleError = useErrorHandler();

  const { showInfo } = useToast();

  const onClickCloseOrder = async () => {
    setLoadingCloseOrder(true);
    try {
      await closeOrder(order.id);
      setOrders((prevState) =>
        prevState.filter((orderItem) => orderItem.id !== order.id)
      );
      setOrderProducts((prevState) =>
        prevState.filter((orderProduct) => orderProduct.orderId !== order.id)
      );
      setPayments((prevState) =>
        prevState.filter((payment) => payment.orderId !== order.id)
      );

      showInfo(t("Order closed."));
    } catch (error) {
      handleError(error);
    }
    setLoadingCloseOrder(false);
  };

  return (
    <div className="grid h-full m-0">
      <div
        className="col-12 lg:col-5 lg:h-full flex flex-column"
        style={{ height: "50%" }}
      >
        <div className="flex flex-grow-1 h-full mb-1 overflow-y-auto">
          <Bill
            order={order}
            orderProducts={orderProducts}
            payments={payments}
            setOrderProducts={setOrderProducts}
          />
        </div>
        <Button
          label={t("Print")}
          className="bg-orange-600 text-white w-full"
          icon={<PrinterOutlined />}
          onClick={handlePrint}
        />
        <div className="hidden">
          <div ref={componentRef}>
            <Bill
              printable
              billPrintSettings={billPrintSettings}
              order={order}
              orderProducts={orderProducts}
              payments={payments}
            />
          </div>
        </div>
      </div>
      <div
        className="col-12 lg:col-7 lg:h-full flex flex-column"
        style={{ height: "50%" }}
      >
        <div className="flex flex-grow-1 w-full h-full mb-1 overflow-y-auto">
          <TabView
            pt={{
              root: { className: "w-full" },
              panelContainer: { className: "py-1 px-0" },
            }}
          >
            {!readOnly && (
              <TabPanel
                header={t("Menu")}
                pt={{
                  headerAction: { className: "p-2" },
                }}
              >
                <MenuTabContent
                  categories={categories}
                  products={products}
                  order={order}
                  setOrderProducts={setOrderProducts}
                />
              </TabPanel>
            )}
            {authorizationToken.user.role === TENANT_ADMIN && (
              <TabPanel
                header={t("Payment")}
                pt={{ headerAction: { className: "p-2" } }}
              >
                <PaymentsTabContent
                  readOnly={readOnly}
                  order={order}
                  payments={payments}
                  setPayments={setPayments}
                />
              </TabPanel>
            )}
            <TabPanel
              header={t("Order")}
              pt={{ headerAction: { className: "p-2" } }}
            >
              <OrderTabContent
                readOnly={readOnly}
                emptyTables={emptyTables}
                order={order}
                setOrders={setOrders}
                setOrderProducts={setOrderProducts}
                setPayments={setPayments}
              />
            </TabPanel>
          </TabView>
        </div>
        {!readOnly && (
          <Button
            label={t("Close Order")}
            className="bg-red-600 text-white w-6"
            onClick={() => {
              onClickCloseOrder();
            }}
            loading={loadingCloseOrder}
          />
        )}
      </div>
    </div>
  );
};

export default OrderContent;
